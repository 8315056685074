import React, { createContext, useContext, useState, useEffect } from 'react'
import { useApolloClient } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { wait } from '@orx/ui/dist/utils'
import { CaseTabsContext } from './case-tabs.provider'
import { restClient } from '../../../rest-client'

interface ResponseSuccess {
  readonly id: string
  readonly statusableId: string
  statusableType: string
  status: string
  updatedAt: Date
  readonly createdAt: Date
}

interface ResponseError {
  field?: string[]
  message: string
}

type ActiveUpdate =
  | 'UPDATE_CASE'
  | 'CREATE_EXTRA_ITEM'
  | 'UPDATE_EXTRA_ITEM'
  | 'DELETE_EXTRA_ITEM'
  | 'UPDATE_CASE_VENDOR'

type UnsetActiveUpdate = () => void

let isConcludeRequestActive = false

interface Context {
  isConcluding: boolean
  caseConcludeErrors: ResponseError[]
  startActiveUpdate: (activeUpdate: ActiveUpdate) => UnsetActiveUpdate
  conclude: () => Promise<void>
  removeErrors: (field: string) => void
}

export const CaseConcludeContext = createContext<Context>({
  isConcluding: false,
  caseConcludeErrors: [],
  startActiveUpdate: () => () => undefined,
  conclude: async () => undefined,
  removeErrors: () => undefined,
})

interface Props {
  caseId: string
}

export const CaseConcludeProvider: React.FC<Props> = ({ children, caseId }) => {
  const { setTabIndex } = useContext(CaseTabsContext)
  const { cache } = useApolloClient()
  const toast = useToast()
  const [isConcluding, setIsConcluding] = useState<boolean>(false)
  const [activeUpdates, setActiveUpdates] = useState<ActiveUpdate[]>([])
  const [errors, setErrors] = useState<ResponseError[]>([])

  function applyErrors(responseErrors: ResponseError[]) {
    setErrors(responseErrors)

    // Global errors should be shown first
    responseErrors
      .filter((error) => error.field == null)
      .forEach((error) => {
        toast(error.message, { variant: 'danger' })
      })

    // Tab specific errors
    let tabIndex: number | null = null
    responseErrors
      .filter((error) => Array.isArray(error.field))
      .forEach((error) => {
        if (error.field && error.field[0] === 'externalFacilityCaseId') {
          if (tabIndex === null) tabIndex = 1
          toast(`Info Tab - ${error.message}`, { variant: 'danger' })
        } else {
          toast(error.message, { variant: 'danger' })
        }
      })

    // Set active tab to first tab with an error
    if (tabIndex !== null) setTabIndex(tabIndex)
  }

  async function concludeRequest(): Promise<void> {
    if (!isConcludeRequestActive) {
      isConcludeRequestActive = true
      try {
        const status = await restClient
          .post(`/cases/${caseId}/conclude`)
          .then(({ data }): ResponseSuccess => data.status)
          .catch((ex) => {
            if (ex.response.status === 422) applyErrors(ex.response.data.errors)
            else {
              toast('An unknown server error has occured', {
                variant: 'danger',
              })
            }
          })

        cache.modify({
          id: `Case:${caseId}`,
          fields: {
            status() {
              return status
            },
            isConcluded() {
              return true
            },
          },
        })
      } catch (ex) {
        toast(
          'Unknown error. Reload the page and verify the case has been concluded.',
          { variant: 'danger' }
        )
      } finally {
        setIsConcluding(false)
        isConcludeRequestActive = false
      }
    }
  }

  function startActiveUpdate(activeUpdate: ActiveUpdate): UnsetActiveUpdate {
    if (activeUpdates.includes(activeUpdate)) {
      throw new Error(`Active update '${activeUpdate}' is already in progress.`)
    }
    setActiveUpdates((prev) => prev.concat(activeUpdate))
    return () => {
      setActiveUpdates((prev) => {
        const newValue = prev.filter((a) => a !== activeUpdate)
        if (isConcluding && !newValue.length) concludeRequest()
        return newValue
      })
    }
  }

  function removeErrors(field: string) {
    const clearedErrors = errors.filter(
      (error) => !error.field?.includes(field)
    )
    setErrors(clearedErrors)
  }

  async function conclude(): Promise<void> {
    await wait(50)
    if (!isConcluding) {
      setIsConcluding(true)
      setErrors([])
      // If empty conclude request now, else will be called from UnsetActiveRequest
      if (!activeUpdates.length) return concludeRequest()
    }
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout
    function pollIsConcluding() {
      if (isConcluding && !errors.length) concludeRequest()
      timeout = setTimeout(pollIsConcluding, 750)
    }

    if (isConcluding) pollIsConcluding()
    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [isConcluding])

  return (
    <CaseConcludeContext.Provider
      value={{
        isConcluding,
        startActiveUpdate,
        conclude,
        caseConcludeErrors: errors,
        removeErrors,
      }}
    >
      {children}
    </CaseConcludeContext.Provider>
  )
}
