import { gql } from '@apollo/client'

export const GET_QCW_FACILITY_LIST = gql`
  query QCWFacilityList($where: String, $active: Boolean) {
    qcwFilter @client @export(as: "where")
    facilities(facilitySearch: { where: $where, active: $active }) {
      id
      name
      phoneNumber
      externalFacilityId
      address {
        street
        city
        state
        zipCode
      }
    }
  }
`
