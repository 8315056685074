import React, { useContext } from 'react'
import { Button, Splash, useToast } from '@orx/ui/dist'
import { CaseConcludeContext } from './case-conclude.provider'
import { CaseImplant, ExtraItem } from '../case.types'

interface Props {
  implants: CaseImplant[]
  extraItems: ExtraItem[]
  externalFacilityCaseId?: string
}

export const CaseConcludeButton: React.FC<Props> = ({
  implants,
  extraItems,
  externalFacilityCaseId,
}) => {
  const { isConcluding, conclude } = useContext(CaseConcludeContext)
  const toast = useToast()

  const handleClick = () => {
    if (!implants.length && !extraItems.length) {
      return toast(
        'At least one implant or extra item must be added before concluding a case',
        { variant: 'danger' }
      )
    } else if (!externalFacilityCaseId) {
      return toast(
        'A facility case ID must be provided before concluding a case',
        { variant: 'danger' }
      )
    } else {
      conclude()
    }
  }

  if (isConcluding) {
    return <Splash text="Concluding Quick Case" />
  }

  return (
    <Button
      size="small"
      variant="primary"
      label="Conclude"
      disabled={isConcluding}
      onClick={handleClick}
    />
  )
}
