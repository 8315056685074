import { gql } from '@apollo/client'
import { CASE_IMPLANTS_FRAGMENT } from '../add-implant/add-implant.types'

export const INFO_TAB_FIELDS_FRAGMENT = gql`
  fragment InfoTabFields on Case {
    externalFacilityCaseId
    caseVendors {
      externalVendorCaseId
      vendorNote
    }
  }
`

export const CASE_PROCEDURES_FRAGMENT = gql`
  fragment CaseProcedures on Case {
    caseProcedures {
      id
      description
      procedure {
        id
        name
      }
      ...CaseImplants
    }
  }
  ${CASE_IMPLANTS_FRAGMENT}
`

export interface CaseProcedures {
  caseProcedures: {
    readonly id: string
    description: string
    procedure: {
      readonly id: string
      name: string
    }
  }[]
}

export interface InfoTabFields {
  externalFacilityCaseId?: string
  externalVendorCaseId?: string
  vendorNote?: string
}

export interface FieldCard {
  disabled: boolean
  setFieldValue: (field: string, value: string) => void
  submitForm: () => Promise<void>
}
