import React from 'react'
import { Box } from 'grommet'
import { PostSurgeryCard } from './post-surgery-card'
import { PostSurgeryPriceCard } from './post-surgery-price-card'
import {
  Facility,
  Vendor,
  User,
  CaseImplant,
  ExtraItem,
} from '../../../case.types'

interface Props {
  implants: CaseImplant[]
  extraItems: ExtraItem[]
  facility: Facility
  vendors: Vendor[]
  rep: User
}

export interface Counts {
  [k: string]: Record<'facility' | 'rep' | 'vendor', number>
}

function restockCounter(total: number, implant: CaseImplant) {
  return (total += implant.implanted + implant.opened + implant.wasted)
}

export const BillingDetails: React.FC<Props> = ({
  implants,
  extraItems,
  facility,
  vendors,
  rep,
}) => {
  const vendorNames = vendors.map((vendor) => vendor.name)
  const restockCounts = vendorNames.reduce((acc, curr) => {
    const facility = implants
      .filter(
        (implant) => implant.vendor === curr && implant.restockTo === 'Facility'
      )
      .reduce(restockCounter, 0)

    const vendor = implants
      .filter(
        (implant) => implant.vendor === curr && implant.restockTo === 'Vendor'
      )
      .reduce(restockCounter, 0)

    const rep = implants
      .filter(
        (implant) =>
          implant.vendor === curr && implant.restockTo === 'Rep Storage'
      )
      .reduce(restockCounter, 0)

    acc[curr] = {
      facility,
      vendor,
      rep,
    }

    return acc
  }, {} as Counts)

  return (
    <Box flex="grow" pad={{ vertical: 'small' }} gap="medium">
      <PostSurgeryPriceCard implants={implants} extraItems={extraItems} />

      <PostSurgeryCard
        variant="facility"
        name={facility.name}
        address={facility.address}
        phone={facility.phoneNumber}
        restockCounts={restockCounts}
      />

      {vendors.map((vendor, index) => (
        <PostSurgeryCard
          key={vendor.id}
          vendorNumber={vendors.length > 1 ? index + 1 : undefined}
          variant="vendor"
          name={vendor.name}
          address={vendor.address}
          phone={vendor.phoneNumber}
          restockCounts={restockCounts}
        />
      ))}

      <PostSurgeryCard
        variant="rep"
        name={rep.fullName}
        address={rep.address}
        email={rep.email}
        restockCounts={restockCounts}
      />
    </Box>
  )
}
