import { gql } from '@apollo/client'

export const GET_QCW_SURGEON_LIST = gql`
  query SurgeonList($where: String) {
    qcwFilter @client @export(as: "where")
    surgeons(where: $where) {
      id
      fullName
      address {
        city
        state
      }
    }
  }
`
