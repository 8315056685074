import { useContext } from 'react'
import { FetchResult, useMutation, gql } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { CaseConcludeContext } from './case-conclude.provider'

const UPDATE_CASE_VENDOR = gql`
  mutation UpdateCaseVendor($caseVendorId: ID!, $values: CaseVendorUpdate!) {
    updateCaseVendor(id: $caseVendorId, updateCaseVendorData: $values) {
      id
      vendorNote
      externalVendorCaseId
    }
  }
`

interface MutationData {
  updateCaseVendor: {
    readonly id: string
    vendorNote?: string
    externalVendorCaseId?: string
  }
}

interface UpdateCaseVendorValues {
  vendorNote?: string
  externalVendorCaseId?: string
}

interface MutationVariables {
  caseVendorId: string
  values: {
    vendorNote?: string
    externalVendorCaseId?: string
  }
}

type UpdateCaseVendor = {
  isUpdatingCaseVendor: boolean
  updateCaseVendor: (
    values: UpdateCaseVendorValues
  ) => Promise<
    FetchResult<
      MutationData,
      Record<string, MutationData>,
      Record<string, MutationData>
    >
  >
}

export function useUpdateCaseVendor(caseVendorId: string): UpdateCaseVendor {
  const toast = useToast()
  const { startActiveUpdate } = useContext(CaseConcludeContext)

  const [update, { loading }] = useMutation<MutationData, MutationVariables>(
    UPDATE_CASE_VENDOR,
    {
      onError(error) {
        console.error(error)
        toast('Could not update case vendor.', { variant: 'danger' })
      },
    }
  )

  return {
    isUpdatingCaseVendor: loading,

    async updateCaseVendor(values: UpdateCaseVendorValues) {
      const finish = startActiveUpdate('UPDATE_CASE_VENDOR')
      return update({
        variables: { caseVendorId, values },
      }).finally(finish)
    },
  }
}
