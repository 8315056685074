import {
  Action,
  State,
  SummaryItem,
  WizardItem,
} from './quick-case-wizard.page.types'
import { qcwFilter } from '../../store'
import { debounce } from 'throttle-debounce'
import { dateToShortDateTime } from '../../utils/date'

export const nextStep: Action = {
  type: 'NEXT_STEP',
}

export const SURGERY_DATE_TIME = 'Surgery Date & Time'

export const setSurgeryTime = (time: string): Action => ({
  type: 'SET_SURGERY_TIME',
  time,
})

export const goToStep = (step: number): Action => ({
  type: 'GOTO_STEP',
  step,
})

export const setProcedures = (procedures: WizardItem[]): Action => ({
  type: 'SET_PROCEDURES',
  procedures,
})

export const setFacility = (facility: WizardItem | null): Action => ({
  type: 'SET_FACILITY',
  facility,
})

export const setSurgeon = (surgeon: WizardItem | null): Action => ({
  type: 'SET_SURGEON',
  surgeon,
})
export const setVendors = (vendors: WizardItem[]): Action => ({
  type: 'SET_VENDORS',
  vendors,
})

export const setFilter = (filter: string): Action => ({
  type: 'SET_FILTER',
  filter,
})

const getPlaceholder = (step: number): string => {
  switch (step) {
    case 1:
      return 'Search for procedures'
    case 2:
      return 'Search for a facility'
    case 3:
      return 'Search for a surgeon'
    case 4:
      return 'Search for vendors'
    default:
      return ''
  }
}

const getTitle = (step: number): string => {
  switch (step) {
    case 1:
      return 'Add Procedures'
    case 2:
      return 'Select a Facility'
    case 3:
      return 'Select a Surgeon'
    case 4:
      return 'Add Vendors'
    case 5:
      return 'Confirm Information'
    default:
      return ''
  }
}

const updateCanContinue = (state: State): State => {
  switch (state.step) {
    case 1:
      return { ...state, canContinue: state.wizardData.procedures.length > 0 }
    case 2:
      return { ...state, canContinue: Boolean(state.wizardData.facility) }
    case 3:
      return { ...state, canContinue: Boolean(state.wizardData.surgeon) }
    case 4:
      return { ...state, canContinue: state.wizardData.vendors.length > 0 }
    default:
      return state
  }
}

const updateSummaryList = (state: State): State => {
  const { wizardData } = state
  const summaryList: SummaryItem[][] = [
    [
      {
        ...wizardData.time,
        title: SURGERY_DATE_TIME,
      },
    ],
  ]

  if (wizardData.procedures.length) {
    summaryList.push(
      wizardData.procedures.map((procedure, index) => {
        return {
          ...procedure,
          title:
            wizardData.procedures.length > 1
              ? `Procedure #${index + 1}`
              : 'Procedure',
          action: goToStep(1),
        }
      })
    )
  }
  if (wizardData.facility) {
    summaryList.push([
      {
        ...wizardData.facility,
        title: 'Facility',
        action: goToStep(2),
      },
    ])
  }
  if (wizardData.surgeon) {
    summaryList.push([
      {
        ...wizardData.surgeon,
        title: 'Surgeon',
        action: goToStep(3),
      },
    ])
  }
  if (wizardData.vendors.length) {
    summaryList.push(
      wizardData.vendors.map((vendor, index) => {
        return {
          ...vendor,
          title:
            wizardData.vendors.length > 1 ? `Vendor #${index + 1}` : 'Vendor',
          action: goToStep(4),
        }
      })
    )
  }

  return { ...state, summaryList }
}

export const quickCaseWizardInitialState = (time: string): State => {
  const step = 1
  return updateSummaryList({
    step: step,
    isEditingStep: false,
    title: getTitle(step),
    canContinue: false,
    wizardData: {
      time: {
        id: '',
        name: dateToShortDateTime(time),
      },
      procedures: [],
      surgeon: null,
      facility: null,
      vendors: [],
    },
    summaryList: [],
    filter: '',
    placeholder: getPlaceholder(step),
    surgeryTime: time,
  })
}

const debouncedQCWFilterVar = debounce(400, false, (value: string | null) => {
  qcwFilter(value)
})

const setQCWFilter = (filter: string | null) => {
  const newFilter = filter && filter.length > 2 ? filter : null
  if (qcwFilter() !== newFilter) {
    debouncedQCWFilterVar(newFilter)
  }
}

export const quickCaseWizardReducer = (state: State, action: Action): State => {
  const gotoEnd = state.isEditingStep && state.step !== 5
  switch (action.type) {
    case 'NEXT_STEP':
      const nextStep = state.step + 1
      setQCWFilter(null)
      return updateCanContinue({
        ...state,
        step: gotoEnd ? 5 : nextStep,
        isEditingStep: state.isEditingStep || nextStep === 5,
        title: getTitle(nextStep),
        placeholder: getPlaceholder(nextStep),
        filter: '',
      })
    case 'GOTO_STEP':
      setQCWFilter(null)
      return updateCanContinue({
        ...state,
        step: gotoEnd ? 5 : action.step,
        title: getTitle(action.step),
        placeholder: getPlaceholder(action.step),
        filter: '',
      })
    case 'SET_PROCEDURES':
      return updateCanContinue(
        updateSummaryList({
          ...state,
          wizardData: { ...state.wizardData, procedures: action.procedures },
        })
      )
    case 'SET_FACILITY':
      return updateCanContinue(
        updateSummaryList({
          ...state,
          wizardData: { ...state.wizardData, facility: action.facility },
        })
      )
    case 'SET_SURGEON':
      return updateCanContinue(
        updateSummaryList({
          ...state,
          wizardData: { ...state.wizardData, surgeon: action.surgeon },
        })
      )
    case 'SET_VENDORS':
      return updateCanContinue(
        updateSummaryList({
          ...state,
          wizardData: { ...state.wizardData, vendors: action.vendors },
        })
      )
    case 'SET_FILTER':
      setQCWFilter(action.filter)
      return updateCanContinue({
        ...state,
        filter: action.filter,
      })
    case 'SET_SURGERY_TIME':
      return updateSummaryList({
        ...state,
        surgeryTime: action.time,
        wizardData: {
          ...state.wizardData,
          time: {
            id: '',
            name: dateToShortDateTime(action.time),
          },
        },
      })
    case 'END_EDITING':
      return {
        ...state,
        isEditingStep: false,
      }
    default:
      return state
  }
}
