import { gql } from '@apollo/client'

export const GET_USER = gql`
  query User {
    user {
      id
      firstName
      lastName
      fullName
      email
      phoneNumber
      address {
        id
        street
        unit
        city
        state
        zipCode
      }
      role
    }
  }
`
