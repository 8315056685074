import React, { useState } from 'react'
import { Box, Layer } from 'grommet'
import { useQuery, useMutation, gql } from '@apollo/client'
import {
  ActionButton,
  Text,
  useToast,
  ConfirmationModal,
  Header,
  Spinner,
} from '@orx/ui/dist'
import { CloseIcon } from '@orx/ui/dist/icons'
import { ListSelect, SelectListItem } from '..'
import {
  CASE_PROCEDURES_FRAGMENT,
  CaseProcedures,
} from '../../pages/case/components/info/info.types'
import { CaseImplant } from '../../pages/case/case.types'
import { Layout } from '../layout'

export const PROCEDURES = gql`
  query Procedures {
    procedures {
      id
      name
    }
  }
`

export const UPDATE_CASE_PROCEDURES = gql`
  mutation UpdateCaseProcedures($caseId: ID!, $values: CaseUpdate!) {
    updateCase(id: $caseId, updateCaseData: $values) {
      id
      ...CaseProcedures
    }
  }
  ${CASE_PROCEDURES_FRAGMENT}
`

interface ProceduresQueryData {
  procedures: {
    id: string
    name: string
  }[]
}

interface UpdateProceduresMutationData
  extends Omit<CaseProcedures, 'description'> {
  id: string
}

interface UpdateProceduresMutationVariables {
  caseId: string
  values: {
    procedures: string[]
  }
}

interface SelectProcedureProps {
  caseId: string
  close: () => void
  implants: CaseImplant[]
  existingProcedures: string[]
  onSuccess: () => void
}

export const SelectProcedures: React.FC<SelectProcedureProps> = ({
  caseId,
  close,
  implants,
  existingProcedures,
  onSuccess,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [selectedProcedures, setSelectedProcedures] = useState<string[]>([])
  const toast = useToast()

  const { data, loading, error } = useQuery<ProceduresQueryData>(PROCEDURES, {
    onError(error) {
      console.error(error)
      toast('Could not retrieve procedure list', { variant: 'danger' })
    },
  })

  const [updateCaseProcedures, { loading: updating }] = useMutation<
    UpdateProceduresMutationData,
    UpdateProceduresMutationVariables
  >(UPDATE_CASE_PROCEDURES, {
    onCompleted() {
      toast('Case procedures updated', { variant: 'success' })
      onSuccess()
    },
    onError(error) {
      console.error(error)
      toast('Could not update procedures', { variant: 'danger' })
    },
  })

  const proceduresListItems: SelectListItem[] = (
    data?.procedures || []
  ).map(({ id, name }) => ({ id, label: name }))

  const handleUpdate = (procedures: string[]) => {
    updateCaseProcedures({
      variables: {
        caseId,
        values: { procedures },
      },
    })
  }

  const handleSubmit = (procedures: string[]) => {
    setSelectedProcedures(procedures)

    if (
      implants.length &&
      !implants.some((implant) =>
        procedures.includes(implant.procedure.procedureId)
      )
    ) {
      setShowConfirmation(true)
    } else {
      handleUpdate(procedures)
    }
  }

  if (error || loading) {
    return (
      <Box flex background="champage" justify="center" align="center">
        {error && <Text>{error.message}</Text>}
        {loading && (
          <Box>
            <Spinner />
            <Text margin={{ top: 'small' }}>Loading...</Text>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <Layer full>
      <Box fill overflow="auto">
        <Layout
          header={
            <Header>
              <Text>Select Procedures</Text>
              <Box margin="auto" />
              <ActionButton
                variant="grey"
                onClick={close}
                icon={<CloseIcon color="orx-purple" />}
              />
            </Header>
          }
        >
          <Box as="main" flex="grow" background="champagne">
            <ListSelect
              required
              items={proceduresListItems}
              existingItemIds={existingProcedures}
              isUpdating={updating}
              onSubmit={handleSubmit}
            />
            <ConfirmationModal
              open={showConfirmation}
              closeModal={() => setShowConfirmation(false)}
              title="Remove procedure?"
              body="You're removing a procedure and all implants associated with it."
              confirmLabel="Remove"
              confirm={() => {
                handleUpdate(selectedProcedures)
                setShowConfirmation(false)
              }}
            />
          </Box>
        </Layout>
      </Box>
    </Layer>
  )
}
