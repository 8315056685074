import React, { useState } from 'react'
import {
  ImplantDetails,
  FormikValues,
  IMPLANT_ACTION_VALUES,
} from './implant-details'
import { Button, ConfirmationModal } from '@orx/ui/dist'
import { useDeleteProcedureImplant } from './use-delete-procedure-implant'
import { useUpdateProcedureImplant } from './use-update-procedure-implant'
import { CaseImplant, CaseProcedure } from '../../../case.types'

interface Props {
  caseProcedures: CaseProcedure[]
  selectedImplant: CaseImplant
  closeDetails: () => void
  procedureDate: Date
}

export const EditImplant: React.FC<Props> = ({
  caseProcedures,
  selectedImplant,
  closeDetails,
  procedureDate,
}) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

  const deleteImplant = useDeleteProcedureImplant(closeDetails)
  const updateImplant = useUpdateProcedureImplant(closeDetails)

  const handleSubmit = async (
    values: FormikValues,
    CaseProcedureId: string,
    action: 'primary' | 'secondary'
  ) => {
    if (action === 'secondary') {
      setShowDeleteConfirmation(true)
    }

    if (action === 'primary') {
      await updateImplant(selectedImplant.id, {
        CaseProcedureId,
        ImplantId: selectedImplant.implantId,
        lotNumber: values.implantLot,
        expirationDate: !values.implantExp ? null : values.implantExp,
        restockTo: values.restock,
        implanted: values.implanted,
        opened: values.opened,
        wasted: values.wasted,
      })
    }
  }

  const implantDetails = [
    {
      term: 'Implant Name',
      definition: selectedImplant.implantName,
    },
    {
      term: 'Vendor',
      definition: selectedImplant.vendor,
    },
    {
      term: 'Unit Price',
      definition: `$${selectedImplant.unitPrice.toLocaleString()}`,
    },
    {
      term: 'REF',
      definition: selectedImplant.refId,
    },
  ]

  return (
    <>
      <ImplantDetails
        variant="edit"
        implantDetailsList={implantDetails}
        caseProcedures={caseProcedures}
        procedureDate={procedureDate}
        implantProcedure={selectedImplant.procedure}
        implantLot={selectedImplant.lotNumber}
        implantExp={selectedImplant.expirationDate}
        implanted={selectedImplant.implanted}
        wasted={selectedImplant.wasted}
        opened={selectedImplant.opened}
        restock={selectedImplant.restockTo}
        closeDetails={closeDetails}
        handleSubmit={handleSubmit}
        primaryAction={
          <Button
            variant="primary"
            label={IMPLANT_ACTION_VALUES['Save & Close']}
          />
        }
        secondaryAction={
          <Button
            variant="secondary"
            color="red"
            label={IMPLANT_ACTION_VALUES['Delete Implant']}
          />
        }
      />
      <ConfirmationModal
        open={showDeleteConfirmation}
        closeModal={() => setShowDeleteConfirmation(false)}
        title="Delete Implant?"
        body="Are you sure you want to delete and remove this implant from the bill? This cannot be undone."
        confirmLabel="Delete"
        confirm={async () => await deleteImplant(selectedImplant.id)}
      />
    </>
  )
}
