import React from 'react'
import { Router } from '@reach/router'
import { DashboardPage } from './dashboard'
import { CasePage } from './case'
import { QuickCaseWizardPage } from './quick-case-wizard'

export default function Pages(): React.ReactElement {
  return (
    <Router>
      <DashboardPage path="/" />
      <QuickCaseWizardPage path="qc-wizard" />
      <CasePage path="case/:caseId" />
    </Router>
  )
}
