import React, { useRef, useState } from 'react'
import { Box, MaskedInput } from 'grommet'
import { ActionButton, Button, Calendar, Text } from '@orx/ui/dist'
import { CloseIcon } from '@orx/ui/dist/icons'
import { splitDateTime, splitTime } from '../../../../utils/date'
import { SurgeryTimePickerProps } from './surgery-time-picker.types'
import styled from 'styled-components'

// todo: Grommet's typings for MaskedInput are incorrect on 2.17.0 and misses the focusIndicator prop
const RemoveFocus = styled(MaskedInput)`
  box-shadow: none !important;
`

export const SurgeryTimePicker: React.FC<SurgeryTimePickerProps> = ({
  time: initialDateTime,
  onNewTime,
}) => {
  // eslint-disable-next-line
  const timeRef = useRef<any>(null) // Cant find Interface that would work. Tried HTMLInputElement, MaskedInputType
  const [initialDate, initialTime] = splitDateTime(initialDateTime)
  const [date, setDate] = useState<string>(initialDate)
  const [time, setTime] = useState<string>(initialTime)

  const onConfirm = () => {
    const validTime = splitTime(time)
    if (validTime) {
      const newDate = new Date(date)
      newDate.setHours(validTime.hours)
      newDate.setMinutes(validTime.minutes)
      onNewTime(newDate.toISOString())
    } else {
      ;(timeRef.current || {}).focus()
    }
  }

  return (
    <Box
      height="29rem"
      width="26rem"
      pad="medium"
      margin="auto"
      gap="medium"
      data-testid="modal"
    >
      <Calendar
        date={date}
        onSelect={setDate as (select: string | string[]) => void}
      />
      <Box direction="row" gap="small" align="center">
        <Text variant="body-copy">Time:</Text>
        <Box
          round="xsmall"
          background="white"
          align="center"
          direction="row"
          border={{ side: 'all', color: 'ice-grey', size: 'small' }}
        >
          {/*
              Oleg: Masked input should be wrapped in our own component.
              This one is probably temporary. Just a means to select a time.
              It does not match designs. It is done to save time.
              We are using what we have out of Grommet has to offer.
              In a future we would build a proper time picker.
            */}
          <RemoveFocus
            plain={true}
            ref={timeRef}
            mask={[
              {
                length: [1, 2],
                regexp: /^1[1-2]$|^[0-9]$/,
                placeholder: 'HH',
                options: [
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                  '11',
                  '12',
                ],
              },
              { fixed: ':' },
              {
                length: 2,
                regexp: /^[0-5][0-9]$|^[0-9]$/,
                placeholder: 'MM',
                options: ['00', '15', '30', '45'],
              },
              { fixed: ' ' },
              {
                length: 2,
                regexp: /^[ap]m$|^[AP]M$|^[aApP]$/,
                placeholder: 'AM/PM',
                options: ['AM', 'PM'],
              },
            ]}
            value={time}
            name="maskedInput"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setTime(event.target.value)
            }
          />
          <ActionButton
            variant="transparent"
            size="small"
            margin={{ right: 'small' }}
            onClick={() => {
              setTime('')
              ;(timeRef.current || {}).focus()
            }}
            icon={<CloseIcon size="medium" color="orx-green" />}
          />
        </Box>
        <Button
          margin={{ left: 'auto' }}
          variant="primary"
          label="Confirm"
          data-testid="submit"
          onClick={onConfirm}
        />
      </Box>
    </Box>
  )
}
