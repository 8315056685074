import { gql } from '@apollo/client'

export const EXTRA_ITEM_FRAGMENT = gql`
  fragment CaseUpdateExtraItem on ExtraItem {
    id
    name
    price
    description
    caseVendor {
      id
    }
  }
`

export interface FormValues {
  name: string
  caseVendorId: string
  price: number
  description?: string
}
