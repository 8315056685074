import React, { useState } from 'react'
import { Box } from 'grommet'
import {
  ActionButton,
  BottomBar,
  IconButton,
  Button,
  Header,
  Text,
  SearchInput,
} from '@orx/ui/dist'
import { Layout } from '../../../../../components/layout'
import { ActionArrowLeftIcon, CloseIcon } from '@orx/ui/dist/icons'
import { CaseProcedure } from '../../../case.types'

interface Props {
  closeSelectProcedure: () => void
  exitSelectProcedure: () => void
  caseProcedures: CaseProcedure[]
  selectedProcedure: CaseProcedure
  updateSelectedProcedure: (procedure: CaseProcedure) => void
}

export const SelectProcedure: React.FC<Props> = ({
  closeSelectProcedure,
  exitSelectProcedure,
  caseProcedures,
  selectedProcedure,
  updateSelectedProcedure,
}) => {
  // state for local selection before updating
  const [currentProcedure, setCurrentProcedure] = useState<CaseProcedure>(
    selectedProcedure
  )
  const [procedureFilter, setProcedureFilter] = useState('')

  return (
    <Layout
      header={
        <Header>
          <IconButton
            a11yTitle="back"
            onClick={closeSelectProcedure}
            icon={<ActionArrowLeftIcon />}
            size="small"
            margin={{ right: 'small' }}
          />
          <Text variant="body-copy">Switch Procedures</Text>
          <Box margin="auto" />
          <ActionButton
            variant="grey"
            onClick={exitSelectProcedure}
            icon={<CloseIcon color="orx-purple" />}
          />
        </Header>
      }
      subHeader={
        <Box round="xsmall" background="white" fill="horizontal">
          <SearchInput
            placeholder="Search for a procedure"
            value={procedureFilter}
            setValue={setProcedureFilter}
          />
        </Box>
      }
    >
      <Box as="main" flex="grow" background="champagne">
        <Box>
          {caseProcedures.map((procedure) => {
            const procedureOption = (
              <Box
                key={procedure.id}
                border={{
                  side: 'bottom',
                  size: 'xsmall',
                  color: 'white',
                }}
                pad="medium"
                direction="row"
                justify="between"
                align="center"
                gap="xsmall"
                height="5.375rem" // set height - button variants have different heights
              >
                <Box width="medium">
                  <Text>{procedure.name}</Text>
                </Box>
                <Box width="small">
                  <Button
                    onClick={() => setCurrentProcedure(procedure)}
                    label={
                      currentProcedure.id === procedure.id
                        ? 'Selected'
                        : 'Select'
                    }
                    variant={
                      currentProcedure.id === procedure.id
                        ? 'primary'
                        : 'secondary'
                    }
                  />
                </Box>
              </Box>
            )

            if (procedureFilter === '') {
              return procedureOption
            } else if (procedure.name.match(new RegExp(procedureFilter, 'i'))) {
              return procedureOption
            }
          })}
        </Box>
      </Box>
      <BottomBar>
        <Box fill="horizontal" direction="row" justify="between" align="center">
          <Text>Procedure for Implant</Text>
          <Button
            onClick={() => {
              updateSelectedProcedure(currentProcedure)
              closeSelectProcedure()
            }}
            variant="primary"
            label="Update"
          />
        </Box>
      </BottomBar>
    </Layout>
  )
}
