import React, { useState } from 'react'
import { Box } from 'grommet'
import { BottomBar, Button, Tab, Tabs } from '@orx/ui/dist'
import { Layout } from '../../../../components'
import {
  Facility,
  Vendor,
  User,
  CaseImplant,
  ExtraItem,
} from '../../case.types'
import {
  PostSurgeryHeaderAction,
  BillingDetails,
  Log,
  ShareBill,
} from './components'
import { CaseStatusType } from '../../../../components/case-status'

interface Props {
  caseId: string
  caseStatus: CaseStatusType
  facility: Facility
  vendors: Vendor[]
  rep: User
  implants: CaseImplant[]
  extraItems: ExtraItem[]
}

export const PostSurgery: React.FC<Props> = ({
  caseId,
  caseStatus,
  facility,
  vendors,
  rep,
  implants,
  extraItems,
}) => {
  const [taxIndex, setTaxIndex] = useState(1)
  const [showShareBill, setShowShareBill] = useState(false)

  // get only the vendors with implants or an extra item
  const activeVendors = vendors.filter(
    (vendor) =>
      implants.find((implant) => implant.vendor === vendor.name) ||
      extraItems.find((item) => item.caseVendorId === vendor.caseVendorId)
  )

  if (showShareBill) {
    return (
      <ShareBill
        caseStatus={caseStatus}
        caseId={caseId}
        closeShareBill={() => setShowShareBill(false)}
        vendors={activeVendors}
      />
    )
  }

  return (
    <>
      <Layout
        title="Post Surgery Overview"
        HeaderAction={PostSurgeryHeaderAction}
      >
        <Box as="main" flex="grow" background="champagne" pad="small">
          <Tabs activeIndex={taxIndex} onActive={setTaxIndex}>
            <Tab title="The Log">
              <Log implants={implants} vendors={activeVendors} />
            </Tab>
            <Tab title="Billing Details">
              <BillingDetails
                implants={implants}
                extraItems={extraItems}
                facility={facility}
                vendors={activeVendors}
                rep={rep}
              />
            </Tab>
          </Tabs>
        </Box>
      </Layout>
      <BottomBar>
        <Box fill="horizontal">
          <Button
            variant="secondary"
            label="Share Bill"
            onClick={() => setShowShareBill(true)}
          />
        </Box>
      </BottomBar>
    </>
  )
}
