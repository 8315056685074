import React from 'react'
import { navigate } from '@reach/router'
import { Auth0Provider } from '@auth0/auth0-react'

export const Auth0WrapperWithHistory: React.FC = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || ''
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || ''
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || ''

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      audience={audience}
      onRedirectCallback={() => navigate('/', { replace: true })}
    >
      {children}
    </Auth0Provider>
  )
}
