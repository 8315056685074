import React, { useContext, useState } from 'react'
import { Box } from 'grommet'
import { Tabs, Tab, Text } from '@orx/ui/dist'
import { SearchIcon } from '@orx/ui/dist/icons'
import { Bill, ExtraItems, Info, CaseConcludeButton, CaseTabsContext } from '.'
import {
  CaseProcedure,
  CaseImplant,
  Facility,
  Surgeon,
  Vendor,
} from '../case.types'
import { Layout } from '../../../components'
import { ExtraItem } from '../case.types'
import { AddImplantPage } from './add-implant/add-implant'
import { EditImplant } from './add-implant/components'

interface Props {
  readonly id: string
  procedures: CaseProcedure[]
  implants: CaseImplant[]
  externalFacilityCaseId?: string
  procedureDate: Date
  facility: Facility
  surgeon: Surgeon
  caseVendors: Vendor[]
  extraItems: ExtraItem[]
}

export const CaseOverview: React.FC<Props> = ({
  id,
  procedures,
  implants,
  externalFacilityCaseId,
  procedureDate,
  facility,
  surgeon,
  caseVendors,
  extraItems,
}) => {
  const { tabIndex, setTabIndex } = useContext(CaseTabsContext)
  const [showAddImplant, setShowAddImplant] = useState(false)
  const [showEditImplant, setShowEditImplant] = useState(false)
  const [selectedImplant, setSelectedImplant] = useState<null | CaseImplant>(
    null
  )

  if (showAddImplant) {
    return (
      <AddImplantPage
        caseVendors={caseVendors.map((caseVendor) => caseVendor.id)}
        caseProcedures={procedures}
        facilityId={facility.id}
        closeAddImplant={() => setShowAddImplant(false)}
        procedureDate={procedureDate}
      />
    )
  }

  if (showEditImplant && selectedImplant) {
    return (
      <EditImplant
        selectedImplant={selectedImplant}
        caseProcedures={procedures}
        closeDetails={() => setShowEditImplant(false)}
        procedureDate={procedureDate}
      />
    )
  }

  return (
    <Layout
      title="Quick Case"
      HeaderAction={() => (
        <CaseConcludeButton
          implants={implants}
          extraItems={extraItems}
          externalFacilityCaseId={externalFacilityCaseId}
        />
      )}
      subHeader={
        <Box fill="horizontal">
          <Box
            background="white"
            pad="14px"
            direction="row"
            align="center"
            justify="between"
            round="xsmall"
            onClick={() => setShowAddImplant(true)}
          >
            <Text color="orx-grey" variant="body-copy-small">
              Add Implants
            </Text>
            <SearchIcon color="orx-green" size="small" />
          </Box>
        </Box>
      }
    >
      <Box flex="grow" background="champagne" pad="small" gap="small">
        <Tabs activeIndex={tabIndex} onActive={setTabIndex}>
          <Tab title="The Bill">
            <Bill
              implants={implants}
              vendors={caseVendors}
              setShowEditImplant={setShowEditImplant}
              setSelectedImplant={setSelectedImplant}
            />
          </Tab>
          <Tab title="Case Info">
            <Info
              caseId={id}
              externalFacilityCaseId={externalFacilityCaseId}
              procedureDate={procedureDate}
              facility={facility}
              surgeon={surgeon}
              procedures={procedures}
              vendors={caseVendors}
              implants={implants}
            />
          </Tab>
          <Tab title="Extra Items">
            <ExtraItems items={extraItems} vendors={caseVendors} />
          </Tab>
        </Tabs>
      </Box>
    </Layout>
  )
}
