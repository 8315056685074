import { ColorsType } from '@orx/ui/dist/theme'
export interface CaseStatusProps {
  caseStatus: CaseStatusType
  showLabel?: boolean
}

export const CASE_STATUSES = [
  'Scheduled',
  'Not Shared',
  'Shared',
  'Overdue',
] as const

export type CaseStatusType = typeof CASE_STATUSES[number]

export const caseStatusStyleMap: Record<
  CaseStatusType,
  { color: ColorsType; label: string }
> = {
  Scheduled: {
    color: 'lime',
    label: 'Scheduled',
  },
  'Not Shared': {
    color: 'orange',
    label: 'Not Shared',
  },
  Shared: {
    color: 'light-blue',
    label: 'Shared',
  },
  Overdue: {
    color: 'yellow',
    label: 'Overdue',
  },
}
