import { gql } from '@apollo/client'

export const IMPLANTS_FRAGMENT = gql`
  fragment Implants on Implant {
    id
    name
    externalImplantId
    refId
    description
    productFamily
    globalRegistryProvider
    sizes {
      value
      dimension
      unitOfMeasure
    }
    vendor {
      id
      name
    }
  }
`

export const CASE_IMPLANTS_FRAGMENT = gql`
  fragment CaseImplants on CaseProcedure {
    caseProcedureImplants {
      implant {
        ...Implants
      }
      id
      lotNumber
      expirationDate
      restockTo
      implanted
      opened
      wasted
      unitPrice
      extendedPrice {
        implanted
        opened
        wasted
      }
    }
  }
  ${IMPLANTS_FRAGMENT}
`

export interface Sizes {
  dimension: string
  unitOfMeasure: string
  value: string
}

export interface Implant {
  readonly id: string
  name: string
  externalImplantId: string
  refId: string
  description: string
  productFamily: string
  globalRegistryProvider: string
  sizes: Sizes[] | null
  vendor: {
    readonly id: string
    name: string
  }
  extendedPrice: {
    implanted: number
    opened: number
    wasted: number
  }
}

export interface ImplantPrice {
  readonly id: string
  name: string
  vendor: string
  price: number
  ref: string
}

export interface CaseProcedureImplant {
  readonly id: string
  implant: Implant
  lotNumber: string
  expirationDate: string
  refId: string
  restockTo: string
  implanted: number
  opened: number
  wasted: number
  unitPrice: number
  extendedPrice: {
    implanted: number
    opened: number
    wasted: number
  }
}
