import React, { MouseEventHandler } from 'react'
import { Box } from 'grommet'
import { Icon as IconComponent } from 'grommet-icons'
import { Button, Text } from '@orx/ui/dist'

interface NoItemsProps {
  children: string | JSX.Element | JSX.Element[]
  Icon: IconComponent
  mainIconSize?: string
  buttonLabel: string
  buttonIcon?: JSX.Element
  onClick: MouseEventHandler | string
}

export const NoItems: React.FC<NoItemsProps> = ({
  children,
  Icon,
  mainIconSize = '200px',
  buttonLabel,
  buttonIcon,
  onClick,
}) => (
  <Box gap="small" margin="auto" alignContent="center">
    <Box
      width="18rem"
      height="18rem"
      align="center"
      justify="center"
      alignSelf="center"
      round="full"
      background={{ color: 'champagne-dark' }}
    >
      <Icon size={mainIconSize} color="orx-purple" />
    </Box>
    <Text variant="xlarge-heading" textAlign="center">
      {children}
    </Text>
    <Box flex={false} align="center">
      <Button
        variant="primary"
        label={buttonLabel}
        icon={buttonIcon}
        {...(typeof onClick === 'string' ? { href: onClick } : { onClick })}
      />
    </Box>
  </Box>
)
