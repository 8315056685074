import React from 'react'
import { useImplantPriceQuery } from '../use-implant-price-query'
import { useCreateProcedureImplant } from './use-create-procedure-implant'
import {
  ImplantDetails,
  FormikValues,
  IMPLANT_ACTION_VALUES,
} from './implant-details'
import { Box } from 'grommet'
import { Button, Text, useToast, Spinner } from '@orx/ui/dist'
import { CaseProcedure } from '../../../case.types'

interface Props {
  caseProcedures: CaseProcedure[]
  implantId: string
  facilityId: string
  closeDetails: () => void
  closeAddImplant: () => void
  procedureDate: Date
}

export const NewImplant: React.FC<Props> = ({
  caseProcedures,
  closeAddImplant,
  implantId,
  facilityId,
  closeDetails,
  procedureDate,
}) => {
  const toast = useToast()
  const onSuccess = () => toast('Implant Added', { variant: 'success' })
  const onError = () =>
    toast('Implant could not be added', { variant: 'danger' })

  const create = useCreateProcedureImplant(onSuccess, onError)
  const { implantPriceData, loading, error } = useImplantPriceQuery(
    implantId,
    facilityId
  )

  const implantDetails = [
    {
      term: 'Implant Name',
      definition: implantPriceData?.name,
    },
    {
      term: 'Vendor',
      definition: implantPriceData?.vendor,
    },
    {
      term: 'Unit Price',
      definition: `$${implantPriceData?.price.toLocaleString()}`,
    },
    {
      term: 'REF',
      definition: implantPriceData?.ref,
    },
  ]

  const handleSubmit = async (
    values: FormikValues,
    CaseProcedureId: string,
    action: 'primary' | 'secondary'
  ) => {
    await create({
      values: {
        CaseProcedureId,
        ImplantId: implantId,
        lotNumber: values.implantLot,
        expirationDate: !values.implantExp ? null : values.implantExp,
        restockTo: values.restock,
        implanted: values.implanted,
        opened: values.opened,
        wasted: values.wasted,
        unitPrice: implantPriceData?.price || 0,
      },
    })

    if (action === 'secondary') {
      closeDetails()
    }

    if (action === 'primary') {
      closeAddImplant()
    }
  }

  if (error || loading) {
    return (
      <Box fill background="champagne" justify="center" align="center">
        {error && <Text margin="auto">{error.message}</Text>}
        {loading && (
          <Box flex="grow" justify="center" align="center">
            <Spinner />
            <Text margin={{ top: 'small' }}>Loading...</Text>
          </Box>
        )}
      </Box>
    )
  }

  return (
    <ImplantDetails
      caseProcedures={caseProcedures}
      procedureDate={procedureDate}
      closeDetails={closeDetails}
      implantDetailsList={implantDetails}
      handleSubmit={handleSubmit}
      primaryAction={
        <Button variant="primary" label={IMPLANT_ACTION_VALUES['Finish']} />
      }
      secondaryAction={
        <Button
          variant="secondary"
          label={IMPLANT_ACTION_VALUES['Finish & Add Another']}
        />
      }
    />
  )
}
