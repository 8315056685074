import React from 'react'
import { RouteComponentProps } from '@reach/router'
import { Box } from 'grommet'
import { Text, Spinner } from '@orx/ui/dist'
import {
  CaseConcludeProvider,
  CaseOverview,
  PostSurgery,
  CaseTabsProvider,
} from './components'
import { useCaseQuery } from './use-case-query'

interface RouteParams {
  caseId: string
}

export const CasePage: React.FC<RouteComponentProps<RouteParams>> = ({
  caseId,
}) => {
  if (!caseId) return <Text margin="auto">Case ID not provided</Text>

  const { caseData, loading, error } = useCaseQuery(caseId)

  if (error || loading || !caseData) {
    return (
      <Box fill background="champage" justify="center" align="center">
        {error && <Text>{error.message}</Text>}
        {loading && (
          <Box fill background="champagne" justify="center" align="center">
            <Spinner />
            <Text margin={{ top: 'small' }}>Loading...</Text>
          </Box>
        )}
        {!loading && !caseData && <Text margin="auto">Case not found</Text>}
      </Box>
    )
  }

  return !caseData.isConcluded ? (
    <CaseTabsProvider>
      <CaseConcludeProvider caseId={caseId}>
        <CaseOverview {...caseData} />
      </CaseConcludeProvider>
    </CaseTabsProvider>
  ) : (
    <PostSurgery
      caseId={caseId}
      caseStatus={caseData.status}
      facility={caseData.facility}
      extraItems={caseData.extraItems}
      vendors={caseData.caseVendors}
      rep={caseData.user}
      implants={caseData.implants}
    />
  )
}
