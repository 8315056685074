import { useMutation, gql } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { CASE_DASHBOARD_FRAGMENT } from '../../../graphql/queries/cases'
import { useNavigate } from '@reach/router'

export const SAVE_FOR_LATER = gql`
  mutation CreateCase($input: CaseCreate!) {
    createCase(createCaseData: $input) {
      ...CaseDashboard
    }
  }
  ${CASE_DASHBOARD_FRAGMENT}
`

interface MutationData {
  createCase: {
    id: string
    procedureDate: string
    surgeon: {
      fullName: string
    } | null
    facility: {
      name: string
    } | null
    status: string | null
    caseProcedures: {
      procedure: {
        name: string
      }
    } | null
  }
}

interface MutationVariables {
  input: CaseVariables
}

interface CaseVariables {
  procedureDate: Date
  FacilityId: string
  SurgeonId: string
  vendors: string[]
  procedures: string[]
  patient?: string
}

interface CreateCase {
  createCase: (caseVariables: CaseVariables) => Promise<void>
  loading: boolean
}

export function useCaseCreate(createCaseType: 'start' | 'save'): CreateCase {
  const toast = useToast()
  const navigate = useNavigate()

  const [create, { loading }] = useMutation<MutationData, MutationVariables>(
    SAVE_FOR_LATER,
    {
      onCompleted(data) {
        if (createCaseType === 'start') {
          navigate(`/case/${data.createCase.id}`)
        } else {
          navigate('/')
        }
      },
      onError(error) {
        console.error(error)
        toast('Could not create case', { variant: 'danger' })
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            cases(prev = []) {
              return prev.concat(
                cache.writeFragment({
                  fragment: CASE_DASHBOARD_FRAGMENT,
                  data: data?.createCase,
                })
              )
            },
          },
        })
      },
    }
  )

  const createCase = async (caseVariables: CaseVariables) => {
    create({
      variables: {
        input: caseVariables,
      },
    })
  }

  return { createCase, loading }
}
