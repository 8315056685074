import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from 'grommet'
import { List, Text, Spinner } from '@orx/ui/dist'
import { ProcedureStepProps } from './procedure-step.types'
import { WizardListItem } from '../wizard-list-item'
import { GET_QCW_PROCEDURES_LIST } from '../../../../graphql/queries/procedures'
import { WizardItem } from '../../quick-case-wizard.page.types'
import { QCWProcedureList } from '../../../../graphql/queries/types/QCWProcedureList'

export const ProcedureStep: React.FC<ProcedureStepProps> = ({
  onSelectedChanged,
  selectedProcedures,
}) => {
  const { data, loading, error } = useQuery<QCWProcedureList>(
    GET_QCW_PROCEDURES_LIST
  )
  const addProcedure = (id: string, name: string): void => {
    const procedures: WizardItem[] = [{ id, name }, ...selectedProcedures]
    onSelectedChanged(procedures)
  }
  const removeProcedure = (id: string): void => {
    const procedures = selectedProcedures.filter((value) => value.id !== id)
    onSelectedChanged(procedures)
  }

  const procedures = data?.procedures?.map(({ id, name }) => ({
    id,
    name,
    isSelected: selectedProcedures.find((value) => value.id === id),
  }))

  if (error) return <Text margin="auto">{error.message}</Text>

  if (loading) {
    return (
      <Box flex="grow" align="center" justify="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!procedures || procedures?.length === 0) {
    return <Text margin="auto">No procedures found.</Text>
  }

  return (
    <List data={procedures}>
      {({ id, name, isSelected }) => (
        <WizardListItem
          name={name}
          buttonText="Add"
          buttonSelectedText="Added"
          isSelected={isSelected}
          onSelect={() => {
            isSelected ? removeProcedure(id) : addProcedure(id, name)
          }}
        />
      )}
    </List>
  )
}
