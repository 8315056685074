import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from 'grommet'
import { List, Text, Spinner } from '@orx/ui/dist'
import { SurgeonList } from '../../../../graphql/queries/types/SurgeonList'
import { SurgeonStepProps } from './surgeon-step.types'
import { WizardListItem } from '../wizard-list-item'
import { GET_QCW_SURGEON_LIST } from '../../../../graphql/queries/surgeon'

export const SurgeonStep: React.FC<SurgeonStepProps> = ({
  onSelectedChanged,
  selectedSurgeonId,
}) => {
  const { data, loading, error } = useQuery<SurgeonList>(GET_QCW_SURGEON_LIST)

  const surgeons = data?.surgeons?.map(({ id, fullName, address }) => ({
    id,
    name: fullName,
    address: `${address?.city}, ${address?.state}`,
    isSelected: selectedSurgeonId === id,
  }))

  if (error) return <Text margin="auto">{error.message}</Text>

  if (loading) {
    return (
      <Box flex="grow" align="center" justify="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!surgeons || surgeons?.length === 0) {
    return <Text margin="auto">No surgeons found.</Text>
  }

  return (
    <List data={surgeons}>
      {({ id, name, address, isSelected }) => (
        <WizardListItem
          name={name}
          address={address}
          buttonText="Select"
          buttonSelectedText="Selected"
          isSelected={isSelected}
          onSelect={() =>
            onSelectedChanged(isSelected ? null : { id, name, address })
          }
        />
      )}
    </List>
  )
}
