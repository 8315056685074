import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from 'grommet'
import { List, Text, Spinner } from '@orx/ui/dist'
import { GET_QCW_FACILITY_LIST } from '../../../../graphql/queries/facility'
import { FacilityStepProps } from './facility-step.types'
import { WizardListItem } from '../wizard-list-item'
import { QCWFacilityList } from '../../../../graphql/queries/types/QCWFacilityList'

export const FacilityStep: React.FC<FacilityStepProps> = ({
  onSelectedChanged,
  selectedFacilityId,
}) => {
  const { data, loading, error } = useQuery<QCWFacilityList>(
    GET_QCW_FACILITY_LIST,
    {
      variables: {
        active: true,
      },
    }
  )

  const facilities = data?.facilities?.map(({ id, name, address }) => ({
    id,
    name: name,
    address: `${address?.city}, ${address?.state}`,
    isSelected: selectedFacilityId === id,
  }))

  if (error) return <Text margin="auto">{error.message}</Text>

  if (loading) {
    return (
      <Box flex="grow" align="center" justify="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!facilities || facilities?.length === 0) {
    return <Text margin="auto">No facilities found.</Text>
  }

  return (
    <List data={facilities}>
      {({ id, name, address, isSelected }) => (
        <WizardListItem
          name={name}
          address={address}
          buttonText="Select"
          buttonSelectedText="Selected"
          isSelected={isSelected}
          onSelect={() =>
            onSelectedChanged(isSelected ? null : { id, name, address })
          }
        />
      )}
    </List>
  )
}
