import { useQuery, gql, ApolloError } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { Case, CaseQueryData } from './case.types'
import {
  INFO_TAB_FIELDS_FRAGMENT,
  CASE_PROCEDURES_FRAGMENT,
} from './components/info/info.types'
import { EXTRA_ITEM_FRAGMENT } from './components/extra-items/extra-items.types'

const ADDRESS_FRAGMENT = gql`
  fragment Address on Address {
    id
    street
    unit
    city
    state
    zipCode
  }
`

export const CASE_QUERY = gql`
  query Case($caseId: ID!) {
    case(id: $caseId) {
      id
      procedureDate
      isConcluded
      status
      ...InfoTabFields
      ...CaseProcedures
      facility {
        id
        name
        phoneNumber
        address {
          ...Address
        }
      }
      surgeon {
        id
        fullName
        specialty
      }
      caseVendors {
        id
        vendor {
          id
          name
          phoneNumber
          address {
            ...Address
          }
        }
        extraItems {
          ...CaseUpdateExtraItem
        }
      }
      user {
        id
        fullName
        email
        address {
          ...Address
        }
      }
    }
  }
  ${INFO_TAB_FIELDS_FRAGMENT}
  ${CASE_PROCEDURES_FRAGMENT}
  ${EXTRA_ITEM_FRAGMENT}
  ${ADDRESS_FRAGMENT}
  ${EXTRA_ITEM_FRAGMENT}
`

interface QueryData {
  case: CaseQueryData
}

interface QueryVariables {
  caseId: string
}

type CaseData = Case | null

function parseCaseData({ case: caseData }: QueryData): CaseData {
  const implants = caseData.caseProcedures
    .flatMap(({ caseProcedureImplants, procedure, id }) =>
      caseProcedureImplants.map((implant) => ({
        ...implant,
        procedure,
        caseProcedureId: id,
      }))
    )
    .map(({ implant, expirationDate, caseProcedureId, procedure, ...xs }) => {
      return {
        ...xs,
        implantId: implant.id,
        implantName: implant.name,
        implantDescription: implant.description,
        implantSizes: implant.sizes,
        externalImplantId: implant.externalImplantId,
        refId: implant.refId,
        expirationDate: !expirationDate ? null : expirationDate,
        vendor: implant.vendor.name,
        procedure: {
          caseProcedureId,
          procedureId: procedure.id,
        },
      }
    })

  const extraItems = (caseData.caseVendors || [])
    .flatMap((caseVendor) =>
      caseVendor.extraItems.map((extraItem) => ({
        id: extraItem.id,
        vendorId: caseVendor.vendor.id,
        caseVendorId: caseVendor.id,
        name: extraItem.name,
        price: extraItem.price,
        description: extraItem.description,
        createdAt: new Date(extraItem.createdAt),
      }))
    )
    .sort((a, b) => a.createdAt.getTime() - b.createdAt.getTime())

  return {
    isConcluded: caseData.isConcluded,
    implants,
    extraItems,
    id: caseData.id,
    externalFacilityCaseId: caseData.externalFacilityCaseId,
    procedureDate: new Date(caseData.procedureDate),
    status: caseData.status,
    facility: caseData.facility,
    surgeon: caseData.surgeon,
    user: caseData.user,

    procedures: caseData.caseProcedures.map(({ procedure, ...xs }) => ({
      ...xs,
      procedureId: procedure.id,
      name: procedure.name,
    })),

    caseVendors: (caseData.caseVendors || []).map(
      ({ id, externalVendorCaseId, vendorNote, vendor }) => ({
        ...vendor,
        externalVendorCaseId,
        vendorNote,
        caseVendorId: id,
      })
    ),
  }
}

interface CaseQuery {
  caseData: CaseData
  loading: boolean
  error?: ApolloError
}

export function useCaseQuery(caseId: string): CaseQuery {
  const toast = useToast()

  const { data, loading, error } = useQuery<QueryData, QueryVariables>(
    CASE_QUERY,
    {
      variables: { caseId },
      onError(ex) {
        console.error(ex)
        toast('Could not get case from server.', { variant: 'danger' })
      },
    }
  )

  return {
    loading,
    error,
    caseData: data ? parseCaseData(data) : null,
  }
}
