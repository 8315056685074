import React, { useState } from 'react'
import { Box } from 'grommet'
import { BottomBar, Button, List, ListItem } from '@orx/ui/dist'
import deepEqual from 'deep-equal'

export interface SelectListItem {
  id: string
  label: string
}

interface Props {
  items: SelectListItem[]
  existingItemIds: string[]
  isUpdating: boolean
  required?: boolean
  onSubmit: (selectedProcedureIds: string[]) => void
}

export const ListSelect: React.FC<Props> = ({
  items,
  existingItemIds,
  isUpdating,
  required = false,
  onSubmit,
}) => {
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>(
    existingItemIds
  )

  const listData = items.map((item) => ({
    ...item,
    isSelected: selectedItemIds.includes(item.id),
  }))

  function handleSubmit() {
    if (!deepEqual(existingItemIds, selectedItemIds)) {
      return onSubmit(selectedItemIds)
    }
  }

  return (
    <>
      <Box flex="grow">
        <List data={listData}>
          {({ id, label, isSelected }) => (
            <ListItem
              label={label}
              isSelected={isSelected}
              onSelect={() =>
                setSelectedItemIds((prev) =>
                  isSelected ? prev.filter((a) => a !== id) : prev.concat(id)
                )
              }
            />
          )}
        </List>
      </Box>

      <BottomBar>
        <Box fill="horizontal">
          <Button
            variant="primary"
            label="Continue"
            disabled={
              isUpdating || (required && listData.every((a) => !a.isSelected))
            }
            onClick={handleSubmit}
          />
        </Box>
      </BottomBar>
    </>
  )
}
