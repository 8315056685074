import React, { useState } from 'react'
import { Box } from 'grommet'
import { Button, Text, PlainList } from '@orx/ui/dist'
import { NoItemIcon, AddIcon } from '@orx/ui/dist/icons'
import { UpdateExtraItem } from './update-extra-item'
import { CreateExtraItem } from './create-extra-item'
import { NoItems } from '../../../../components'
import { ExtraItem, Vendor } from '../../case.types'

interface Props {
  items: ExtraItem[]
  vendors: Vendor[]
}

export const ExtraItems: React.FC<Props> = ({ items, vendors }) => {
  const [isCreating, setIsCreating] = useState<boolean>(false)

  return (
    <Box gap="xsmall" margin={{ top: 'medium' }}>
      <Text as="p">
        Add additional costs for fees and services for this case that were not
        accounted for by implants which are billable to the patient.
      </Text>

      <PlainList>
        {items.map(({ id, ...item }, i) => (
          <li key={id}>
            <UpdateExtraItem
              {...item}
              itemId={id}
              index={i}
              vendors={vendors}
            />
          </li>
        ))}
        {isCreating && (
          <li>
            <CreateExtraItem
              index={items.length}
              vendors={vendors}
              close={() => setIsCreating(false)}
            />
          </li>
        )}
      </PlainList>

      {!items.length && !isCreating ? (
        <NoItems
          Icon={NoItemIcon}
          mainIconSize="168px"
          buttonLabel="Add Extra Item"
          buttonIcon={<AddIcon size="small" color="white" />}
          onClick={() => setIsCreating(true)}
        >
          No items currently added
        </NoItems>
      ) : (
        <Button
          margin={{ left: 'auto' }}
          icon={
            <AddIcon
              size="small"
              color={isCreating ? 'ice-grey' : 'orx-green'}
            />
          }
          label="Add Another Item"
          disabled={isCreating}
          onClick={() => setIsCreating(true)}
        />
      )}
    </Box>
  )
}
