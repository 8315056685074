import React, { useContext } from 'react'
import { useMutation, gql } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { ExtraItemForm } from './extra-item-form'
import { EXTRA_ITEM_FRAGMENT } from './extra-items.types'
import { CaseConcludeContext } from '../case-conclude.provider'
import { ExtraItem, Vendor } from '../../case.types'

const CREATE_EXTRA_ITEM = gql`
  mutation CreateExtraItem($input: ExtraItemCreate!) {
    createExtraItem(createExtraItemData: $input) {
      ...CaseUpdateExtraItem
    }
  }
  ${EXTRA_ITEM_FRAGMENT}
`

interface CreateExtraItemData {
  createExtraItem: {
    readonly id: string
    name: string
    price: string
    description?: string
    caseVendor: {
      readonly id: string
    }
  }
}

interface CreateExtraItemVariables {
  input: {
    CaseVendorId: string
    name: string
    price: number
    description?: string
  }
}

interface CreateExtraItemProps {
  index: number
  vendors: Vendor[]
  close: () => void
}

export const CreateExtraItem: React.FC<CreateExtraItemProps> = ({
  close,
  ...props
}) => {
  const toast = useToast()
  const { startActiveUpdate } = useContext(CaseConcludeContext)

  const [create] = useMutation<CreateExtraItemData, CreateExtraItemVariables>(
    CREATE_EXTRA_ITEM,
    {
      update(cache, { data }) {
        cache.modify({
          id: `CaseVendor:${data?.createExtraItem.caseVendor.id}`,
          fields: {
            extraItems(prev = []) {
              return prev.concat(
                cache.writeFragment({
                  fragment: EXTRA_ITEM_FRAGMENT,
                  data: data?.createExtraItem,
                })
              )
            },
          },
        })
      },
      onCompleted() {
        toast('Item Created', { variant: 'success' })
        close()
      },
      onError(error) {
        console.error(error)
        toast('Could not create item', { variant: 'danger' })
      },
    }
  )

  async function handleSubmit({ caseVendorId, ...values }: ExtraItem) {
    const finish = startActiveUpdate('CREATE_EXTRA_ITEM')
    await create({
      variables: {
        input: { ...values, CaseVendorId: caseVendorId },
      },
    }).finally(finish)
  }

  return (
    <ExtraItemForm
      {...props}
      submitButtonText="Create"
      onSubmit={handleSubmit}
      onCancel={close}
      onDelete={close}
    />
  )
}
