import React, { createContext, useState, Dispatch, SetStateAction } from 'react'

interface Context {
  tabIndex: number
  setTabIndex: Dispatch<SetStateAction<number>>
  tabs: Record<TabsType, number>
}

const TABS = ['bill', 'case-info', 'extra-items'] as const
type TabsType = typeof TABS[number]
const tabs: Record<TabsType, number> = {
  bill: 0,
  'case-info': 1,
  'extra-items': 2,
}

export const CaseTabsContext = createContext<Context>({
  tabIndex: 0,
  setTabIndex: (() => undefined) as Dispatch<SetStateAction<number>>,
  tabs,
})

export const CaseTabsProvider: React.FC = ({ children }) => {
  const [tabIndex, setTabIndex] = useState<number>(tabs['bill'])
  return (
    <CaseTabsContext.Provider value={{ tabIndex, setTabIndex, tabs }}>
      {children}
    </CaseTabsContext.Provider>
  )
}
