import { useMutation, gql, StoreObject } from '@apollo/client'
import { CASE_IMPLANTS_FRAGMENT } from '../add-implant.types'
import { useToast } from '@orx/ui/dist'
import { CASE_QUERY } from '../../../use-case-query'
import { CaseQueryData } from '../../../case.types'
import { useParams } from '@reach/router'

const UPDATE_PROCEDURE_IMPLANT = gql`
  mutation updateCaseProcedureImplant(
    $id: ID!
    $values: CaseProcedureImplantUpdate!
  ) {
    updateCaseProcedureImplant(
      id: $id
      updateCaseProcedureImplantData: $values
    ) {
      id
      caseProcedure {
        id
        ...CaseImplants
      }
    }
  }
  ${CASE_IMPLANTS_FRAGMENT}
`

interface MutationData {
  updateCaseProcedureImplant: {
    readonly id: string
    caseProcedure: {
      readonly id: string
    }
  }
}

interface MutationVariables {
  id: string
  values: ImplantUpdate
}

interface ImplantUpdate {
  CaseProcedureId: string
  ImplantId: string
  lotNumber: string
  expirationDate: string | null
  restockTo: string
  implanted: number
  opened: number
  wasted: number
}

export function useUpdateProcedureImplant(
  onSuccess: () => void
): (caseProcedureImplantId: string, values: ImplantUpdate) => Promise<void> {
  const { caseId } = useParams()
  const toast = useToast()
  const [update] = useMutation<MutationData, MutationVariables>(
    UPDATE_PROCEDURE_IMPLANT,
    {
      onCompleted() {
        toast('Implant updated', { variant: 'success' })
        onSuccess()
      },
      onError(error) {
        console.error(error)
        toast('Implant could not be updated', { variant: 'danger' })
      },
      update(cache, { data }) {
        const caseData: { case: CaseQueryData } | null = cache.readQuery({
          query: CASE_QUERY,
          variables: {
            caseId,
          },
        })

        const procedureToRemoveImplant = caseData?.case.caseProcedures.find(
          (procedure) =>
            procedure.id !==
              data?.updateCaseProcedureImplant.caseProcedure.id &&
            procedure.caseProcedureImplants.find(
              (implant) => implant.id === data?.updateCaseProcedureImplant.id
            )
        )

        if (procedureToRemoveImplant) {
          cache.modify({
            id: `CaseProcedure:${procedureToRemoveImplant.id}`,
            fields: {
              caseProcedureImplants(prev: StoreObject[] = [], { readField }) {
                return prev.filter(
                  (i) =>
                    readField('id', i) !== data?.updateCaseProcedureImplant.id
                )
              },
            },
          })
        }
      },
    }
  )

  return async (caseProcedureImplantId: string, values: ImplantUpdate) => {
    update({
      variables: {
        id: caseProcedureImplantId,
        values,
      },
    })
  }
}
