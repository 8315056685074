import React, { useState, ChangeEvent } from 'react'
import { Box } from 'grommet'
import {
  Button,
  ActionButton,
  Text,
  TextInputField,
  TextAreaField,
  Header,
  useToast,
  Splash,
} from '@orx/ui/dist'
import { Layout } from '../../../../../components'
import { CloseIcon } from '@orx/ui/dist/icons'
import { Vendor } from '../../../case.types'
import { Formik, FormikProps, Field, FieldArray } from 'formik'
import * as Yup from 'yup'
import { restClient } from '../../../../../rest-client'
import { CaseStatusType } from '../../../../../components/case-status'
import styled from 'styled-components'

const Form = styled.form`
  margin: 0;
`

interface ErrorResponse {
  message: string
  field?: string[]
  resourceId?: string[]
}

interface VendorEmails {
  caseVendorId: string
  name: string
  emails: string[]
}

export interface FormikValues {
  facilityEmails: string[]
  vendorEmails: VendorEmails[]
  notes: string
}

// utilize custom tests so that the first email for facility/vendors  is always required and all following fields are optional.
const validationSchema: Yup.SchemaOf<FormikValues> = Yup.object().shape({
  facilityEmails: Yup.array()
    .of(Yup.string().trim().email('Invalid Email'))
    .min(1)
    .test('required-email', 'This is a required field', function (value) {
      if (value && value[0]) {
        return true
      }

      return this.createError({
        path: `${this.path}[0]`,
        message: 'This is a required field',
      })
    })
    .defined(),
  vendorEmails: Yup.array()
    .of(
      Yup.object().shape({
        caseVendorId: Yup.string().required(),
        name: Yup.string().required(),
        emails: Yup.array()
          .of(Yup.string().trim().email('Invalid Email'))
          .min(1)
          .test('required-email', 'This is a required field', function (value) {
            if (value && value[0]) {
              return true
            }

            return this.createError({
              path: `${this.path}[0]`,
              message: 'This is a required field',
            })
          }),
      })
    )
    .defined(),
  notes: Yup.string().optional().ensure().defined(),
})

const sharedValidationSchema: Yup.SchemaOf<FormikValues> = Yup.object().shape({
  facilityEmails: Yup.array()
    .of(Yup.string().trim().email('Invalid Email'))
    .defined(),
  vendorEmails: Yup.array()
    .of(
      Yup.object().shape({
        caseVendorId: Yup.string().required(),
        name: Yup.string().required(),
        emails: Yup.array().of(Yup.string().trim().email('Invalid Email')),
      })
    )
    .defined(),
  notes: Yup.string().optional().ensure().defined(),
})

interface Props {
  caseId: string
  caseStatus: CaseStatusType
  closeShareBill: () => void
  vendors: Vendor[]
}

export const ShareBill: React.FC<Props> = ({
  caseId,
  caseStatus,
  closeShareBill,
  vendors,
}) => {
  const [isSendingEmails, setIsSendingEmails] = useState(false)
  const [numberRecipients, setNumberRecipents] = useState(0)

  const initialVendorEmails = vendors.map((vendor) => ({
    caseVendorId: vendor.caseVendorId,
    name: vendor.name,
    emails: [''],
  }))

  const toast = useToast()

  return (
    <>
      <Layout
        header={
          <Header>
            <Text>Share Bill</Text>
            <Box margin="auto" />
            <ActionButton onClick={closeShareBill} icon={<CloseIcon />} />
          </Header>
        }
      >
        <Box as="main" flex="grow" background="champagne">
          <Formik<FormikValues>
            validationSchema={
              caseStatus !== 'Shared'
                ? validationSchema
                : sharedValidationSchema
            }
            initialValues={{
              facilityEmails: [''],
              vendorEmails: initialVendorEmails,
              notes: '',
            }}
            onSubmit={async (values: FormikValues, { setErrors }) => {
              const vendorRecipients = values.vendorEmails
                .map((vendor) => ({
                  caseVendorId: vendor.caseVendorId,
                  emails: vendor.emails
                    .filter((email) => email)
                    .map((email) => email.toLowerCase().trim()), // filter out blank optional values
                }))
                .filter((vendor) => vendor.emails.length) // filter out any vendors that don't have emails
              const facilityRecipients = values.facilityEmails
                .filter((email) => email)
                .map((email) => email.toLowerCase().trim())

              const totalVendorRecipients = vendorRecipients.reduce(
                (acc, curr) => (acc += curr.emails.length),
                0
              )
              const totalRecipients =
                totalVendorRecipients + facilityRecipients.length
              setNumberRecipents(totalRecipients)
              setIsSendingEmails(true)

              try {
                await restClient.post(`/cases/${caseId}/share`, {
                  facilityRecipients,
                  vendorRecipients,
                  repMessage: values.notes,
                })
                toast('Bill shared.', { variant: 'success' })
              } catch (error) {
                console.error(error)
                const errorResponse: ErrorResponse[] =
                  error.response.data.errors

                if (!Array.isArray(errorResponse))
                  return toast('Unable to share bill.', { variant: 'danger' })

                // all errors for facilities or vendors
                const facilityErrors: string[] = []
                const allVendorErrors: { emails: string[] }[] = []

                errorResponse.forEach((error) => {
                  // loop through facility and vendor related errors and attach to specific indexes where the errors should appear
                  if (error?.field?.includes('facilityEmails')) {
                    error?.resourceId?.forEach((resource) => {
                      const errorIndex = values.facilityEmails.findIndex(
                        (email) => email === resource
                      )

                      facilityErrors[errorIndex] = error.message
                    })
                  }

                  if (error?.field?.includes('vendorEmails')) {
                    error?.resourceId?.forEach((resource) => {
                      // vendor errors unique to single vendor
                      const vendorErrors: string[] = []
                      values.vendorEmails.forEach((vendor, index) => {
                        const errorIndex = vendor.emails.findIndex(
                          (email) => email === resource
                        )
                        vendorErrors[errorIndex] = error.message

                        allVendorErrors[index] = {
                          emails: vendorErrors,
                        }
                      })
                    })
                  }
                })

                // set the errors
                setErrors({
                  facilityEmails: facilityErrors,
                  vendorEmails: allVendorErrors,
                })
                toast('Unable to share bill.', { variant: 'danger' })
              } finally {
                setIsSendingEmails(false)
              }
            }}
          >
            {({
              values,
              dirty,
              errors,
              touched,
              setFieldValue,
              setFieldTouched,
              handleSubmit,
              submitForm,
              isSubmitting,
            }: FormikProps<FormikValues>) => (
              <Form onSubmit={handleSubmit}>
                <Box
                  border={{
                    side: 'bottom',
                    size: 'xsmall',
                    color: 'white',
                  }}
                  pad="medium"
                >
                  <Text variant="xlarge-heading" margin={{ bottom: 'small' }}>
                    Facility Recipients
                  </Text>
                  <FieldArray
                    name="facilityEmails"
                    render={({ push }) => (
                      <>
                        {values.facilityEmails.map((email, index) => (
                          <Box key={index}>
                            <Field
                              name={`facilityEmails.${index}`}
                              component={TextInputField}
                              label={`Facility Email (${
                                index === 0 && caseStatus !== 'Shared'
                                  ? 'Required'
                                  : 'Optional'
                              })`}
                              placeholder="email@hospital.com"
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFieldValue(
                                  `facilityEmails.${index}`,
                                  e.target.value
                                )
                              }
                              onBlur={() =>
                                setFieldTouched('facilityEmails', true)
                              }
                              error={
                                'facilityEmails' in errors &&
                                touched.facilityEmails &&
                                errors.facilityEmails &&
                                errors.facilityEmails[index]
                              }
                            />
                          </Box>
                        ))}
                        <Box direction="row" justify="end">
                          <Button
                            variant="secondary"
                            label="+ Add Facility Recipient"
                            onClick={() => push('')}
                          />
                        </Box>
                      </>
                    )}
                  />
                </Box>
                <Box
                  border={{
                    side: 'bottom',
                    size: 'xsmall',
                    color: 'white',
                  }}
                  pad="medium"
                >
                  <Text variant="xlarge-heading" margin={{ bottom: 'small' }}>
                    Vendor Recipients
                  </Text>
                  <Text variant="body-copy-small" margin={{ bottom: 'small' }}>
                    This email will receive your initial bill and also purchase
                    order once issused by the facility.
                  </Text>
                  <Box gap="small">
                    {vendors.map((vendor, vendorIndex) => (
                      <FieldArray
                        key={vendor.id}
                        name={`vendorEmails.${vendorIndex}.emails`}
                        render={({ push }) => (
                          <Box key={vendor.id}>
                            {values.vendorEmails[vendorIndex].emails.map(
                              (email, index) => {
                                // typing for FormikErrors doesn't return the correct shape for the nested array. Forcing typing with using as VendorEmails
                                let vendorEmailError
                                if ('vendorEmails' in errors) {
                                  const allVendorEmailErrors = (errors.vendorEmails ||
                                    [])[vendorIndex] as VendorEmails
                                  vendorEmailError =
                                    allVendorEmailErrors?.emails[index] || null
                                }

                                return (
                                  <Box key={index}>
                                    <Field
                                      name={`vendorEmails.${vendorIndex}.emails.${index}`}
                                      label={`${vendor.name} email (${
                                        index === 0 && caseStatus !== 'Shared'
                                          ? 'Required'
                                          : 'Optional'
                                      })`}
                                      component={TextInputField}
                                      placeholder={`email@${vendor.name}`}
                                      onChange={(
                                        e: ChangeEvent<HTMLInputElement>
                                      ) =>
                                        setFieldValue(
                                          `vendorEmails.${vendorIndex}.emails.${index}`,
                                          e.target.value
                                        )
                                      }
                                      onBlur={() =>
                                        setFieldTouched('vendorEmails', true)
                                      }
                                      error={
                                        touched.vendorEmails && vendorEmailError
                                      }
                                    />
                                  </Box>
                                )
                              }
                            )}
                            <Box direction="row" justify="end">
                              <Button
                                variant="secondary"
                                label="+ Add Vendor Recipient"
                                onClick={() => push('')}
                              />
                            </Box>
                          </Box>
                        )}
                      />
                    ))}
                  </Box>
                </Box>
                <Box pad="medium">
                  <Text variant="xlarge-heading" margin={{ bottom: 'small' }}>
                    Details
                  </Text>
                  <Field
                    label="Notes for Recipients (Optional)"
                    value={values.notes}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('notes', e.target.value)
                    }
                    component={TextAreaField}
                    name="notes"
                  />
                </Box>
                <Box
                  fill="horizontal"
                  background="white"
                  pad={{ horizontal: 'medium', vertical: 'small' }}
                >
                  <Button
                    variant="primary"
                    label="Send"
                    disabled={
                      isSubmitting || !dirty || Object.keys(errors).length > 0
                    }
                    onClick={submitForm}
                  />
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Layout>

      {isSendingEmails && (
        <Splash text={`Sending invoices to ${numberRecipients} recipients`} />
      )}
    </>
  )
}
