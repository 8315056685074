import React from 'react'
import { Box } from 'grommet'
import { Button } from '@orx/ui/dist'
import { useCaseCreate } from './use-case-create'

interface Props {
  facilityId: string
  surgeonId: string
  procedureDate: Date
  vendors: string[]
  procedures: string[]
}

export const ConfirmationButtons: React.FC<Props> = ({
  facilityId,
  surgeonId,
  procedureDate,
  vendors,
  procedures,
}) => {
  const caseVariables = {
    procedureDate,
    FacilityId: facilityId,
    SurgeonId: surgeonId,
    vendors,
    procedures,
  }

  const { createCase: startCase, loading: startLoading } = useCaseCreate(
    'start'
  )
  const { createCase: saveLater, loading: saveLoading } = useCaseCreate('save')

  return (
    <Box
      background="white"
      direction="row"
      pad={{ vertical: 'small', horizontal: 'medium' }}
      align="center"
      justify="between"
      gap="small"
    >
      <Button
        variant="secondary"
        disabled={startLoading || saveLoading}
        onClick={async () => await saveLater(caseVariables)}
        label="Save for later"
      />
      <Button
        variant="primary"
        disabled={startLoading || saveLoading}
        onClick={async () => await startCase(caseVariables)}
        label="Start case now"
      />
    </Box>
  )
}
