import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { ChevronRightIcon } from '@orx/ui/dist/icons'
import { COLOR_VALUES } from '@orx/ui/dist/theme'
import { Sizes } from '../add-implant.types'
import styled from 'styled-components'

const Capatalize = styled(Text)`
  text-transform: capitalize;
`

const DarkText = styled.span`
  color: ${COLOR_VALUES['midnight-blue']};
`

interface Props {
  implantId: string
  implantName: string
  implantDescription: string
  vendorName: string
  refId: string
  sizes: Sizes[] | null
}

export const ImplantListItem: React.FC<Props> = ({
  implantId,
  implantName,
  implantDescription,
  vendorName,
  refId,
  sizes,
}) => (
  <Box key={implantId} justify="between" align="center" direction="row">
    <Box>
      <Text variant="body-copy-xsmall">{vendorName}</Text>
      <Text>{implantName}</Text>
      <Text variant="body-copy-small" color="steel-grey">
        {refId}
      </Text>
      <Text variant="body-copy-small">{implantDescription}</Text>
      <Box direction="row" gap="xxsmall" wrap>
        {sizes &&
          sizes.map((size, index) => (
            <Capatalize
              variant="body-copy-xsmall"
              color="steel-grey"
              key={index}
            >
              {size.dimension}{' '}
              <DarkText>
                {size.value}
                {size.unitOfMeasure}
              </DarkText>
            </Capatalize>
          ))}
      </Box>
    </Box>
    <ChevronRightIcon size="small" />
  </Box>
)
