import React from 'react'
import { Box } from 'grommet'
import { Text } from '@orx/ui/dist'
import { NoImplantIcon } from '@orx/ui/dist/icons'
import { COLOR_VALUES } from '@orx/ui/dist/theme'
import { CaseImplant, Vendor } from '../../case.types'
import { CaseImplantCard } from '../../../../components'

interface Props {
  vendors: Vendor[]
  implants: CaseImplant[]
  setShowEditImplant: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedImplant: React.Dispatch<React.SetStateAction<CaseImplant | null>>
}

export const Bill: React.FC<Props> = ({
  implants,
  vendors,
  setShowEditImplant,
  setSelectedImplant,
}) => {
  const implanted = implants.filter((implant) => implant.implanted > 0)
  const opened = implants.filter((implant) => implant.opened > 0)
  const wasted = implants.filter((implant) => implant.wasted > 0)

  const implantUsages = ['Implanted', 'Opened', 'Wasted'] as const

  const handleSelectImplant = (implant: CaseImplant) => {
    setSelectedImplant(implant)
    setShowEditImplant(true)
  }

  if (implants.length === 0) {
    return (
      <Box flex="grow" align="center" justify="center" pad="medium" gap="small">
        <Box
          width="288px"
          height="288px"
          align="center"
          justify="center"
          alignSelf="center"
          round="full"
          background={{ color: COLOR_VALUES['champagne-dark'] }}
        >
          <NoImplantIcon size="200px" color="orx-purple" />
        </Box>
        <Text variant="xlarge-heading" textAlign="center">
          No implants added to the bill yet
        </Text>
      </Box>
    )
  }

  return (
    <Box flex="grow" gap="medium" pad={{ vertical: 'small' }}>
      {implantUsages.map((usage) =>
        vendors.map((vendor) => {
          if (
            (usage === 'Implanted' &&
              implanted.filter((implant) => implant.vendor === vendor.name)
                .length === 0) ||
            (usage === 'Opened' &&
              opened.filter((implant) => implant.vendor === vendor.name)
                .length === 0) ||
            (usage === 'Wasted' &&
              wasted.filter((implant) => implant.vendor === vendor.name)
                .length === 0)
          ) {
            return null
          }

          return (
            <Box key={`${vendor.id}-${usage}}`}>
              <Box direction="row" justify="between" align="center">
                <Text color="orx-grey">{vendor.name}</Text>
                <Text color="orx-grey">{usage}</Text>
              </Box>
              <Box
                border={{ size: 'small', side: 'bottom', color: 'ice-grey' }}
                margin={{ bottom: 'xsmall' }}
              />
              <Box gap="small">
                {usage === 'Implanted' &&
                  implanted
                    .filter((implant) => implant.vendor === vendor.name)
                    .map((implant, index) => (
                      <CaseImplantCard
                        key={implant.id}
                        onActionButtonClick={() => handleSelectImplant(implant)}
                        variant="implanted"
                        implantNumber={index + 1}
                        referenceNumber={implant.refId}
                        implantName={implant.implantName}
                        implantDescription={implant.implantDescription}
                        implantSizes={implant.implantSizes}
                        implantUsage={implant.implanted}
                        expiryDate={implant.expirationDate}
                        lotNumber={implant.lotNumber}
                      />
                    ))}
                {usage === 'Opened' &&
                  opened
                    .filter((implant) => implant.vendor === vendor.name)
                    .map((implant, index) => (
                      <CaseImplantCard
                        key={implant.id}
                        onActionButtonClick={() => handleSelectImplant(implant)}
                        variant="opened"
                        implantNumber={index + 1}
                        referenceNumber={implant.refId}
                        implantName={implant.implantName}
                        implantDescription={implant.implantDescription}
                        implantSizes={implant.implantSizes}
                        implantUsage={implant.opened}
                        expiryDate={implant.expirationDate}
                        lotNumber={implant.lotNumber}
                      />
                    ))}
                {usage === 'Wasted' &&
                  wasted
                    .filter((implant) => implant.vendor === vendor.name)
                    .map((implant, index) => (
                      <CaseImplantCard
                        key={implant.id}
                        onActionButtonClick={() => handleSelectImplant(implant)}
                        variant="wasted"
                        implantNumber={index + 1}
                        referenceNumber={implant.refId}
                        implantName={implant.implantName}
                        implantDescription={implant.implantDescription}
                        implantSizes={implant.implantSizes}
                        implantUsage={implant.wasted}
                        expiryDate={implant.expirationDate}
                        lotNumber={implant.lotNumber}
                      />
                    ))}
              </Box>
            </Box>
          )
        })
      )}
    </Box>
  )
}
