import React from 'react'
import {
  Card,
  CardHeader,
  DescriptionList,
  DescriptionListItem,
  Text,
} from '@orx/ui/dist'
import { Facility, Surgeon } from '../../case.types'

interface GeneralCardProps {
  procedureDate: Date
  facility?: Facility
  surgeon?: Surgeon
}

export const GeneralCard: React.FC<GeneralCardProps> = ({
  procedureDate,
  facility,
  surgeon,
}) => (
  <Card>
    <CardHeader hasDivider>
      <Text variant="xlarge-heading">General</Text>
    </CardHeader>
    <DescriptionList
      list={([] as DescriptionListItem[])
        .concat(
          !facility
            ? []
            : {
                term: 'Facility',
                definition: (
                  <>
                    {facility.name}
                    <br />
                    {facility.address.city}, {facility?.address.state}
                  </>
                ),
              }
        )
        .concat([
          {
            term: 'Surgery Date & Time',
            definition: procedureDate.toLocaleString(),
          },
        ])
        .concat(
          !surgeon
            ? []
            : {
                term: 'Surgeon',
                definition: (
                  <>
                    {surgeon.fullName}
                    <br />
                    {surgeon.specialty}
                  </>
                ),
              }
        )}
    />
  </Card>
)
