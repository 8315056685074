import React, { useState, useContext, ChangeEvent } from 'react'
import { Card, CardHeader, Text, TextInputField } from '@orx/ui/dist'
import { CaseConcludeContext } from '../case-conclude.provider'
import { useUpdateCase } from '../use-update-case'

interface CaseIdCardProps {
  caseId: string
  externalFaciltyCaseId?: string
}

export const CaseIdCard: React.FC<CaseIdCardProps> = ({
  caseId,
  externalFaciltyCaseId,
}) => {
  const { caseConcludeErrors } = useContext(CaseConcludeContext)
  const [value, setValue] = useState<string>(externalFaciltyCaseId || '')
  const { updateCase, loading } = useUpdateCase(caseId)

  let errorMessage
  if (Array.isArray(caseConcludeErrors)) {
    errorMessage = caseConcludeErrors.find((error) =>
      error.field?.includes('externalFacilityCaseId')
    )?.message
  }

  return (
    <Card>
      <CardHeader hasDivider>
        <Text variant="xlarge-heading">Case ID</Text>
      </CardHeader>
      <Text>
        Please provide the facility Case ID to associate this patient to the
        case and invoice.
      </Text>
      <TextInputField
        name="externalFacilityCaseId"
        label="Case ID (Required)"
        disabled={loading}
        value={value}
        error={errorMessage}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
        onBlur={() => {
          const trimmedValue = value.trim()
          if (
            trimmedValue !==
            (externalFaciltyCaseId === null ? '' : externalFaciltyCaseId)
          ) {
            updateCase({ externalFacilityCaseId: trimmedValue })
          }
        }}
      />
    </Card>
  )
}
