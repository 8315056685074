import React from 'react'
import { useNavigate } from '@reach/router'
import { ActionButton } from '@orx/ui/dist'
import { AddIcon } from '@orx/ui/dist/icons'

export const DashboardHeaderAction: React.FC = () => {
  const navigate = useNavigate()

  return (
    <ActionButton
      size="medium"
      icon={<AddIcon />}
      a11yTitle="Quick Case Wizard"
      onClick={() => navigate('/qc-wizard')}
    />
  )
}
