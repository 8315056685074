import React, { useState } from 'react'
import { Button, Card, CardHeader, PlainList, Text } from '@orx/ui/dist'
import { Procedure } from './procedure'
import { SelectProcedures } from '../../../../../components'
import { CaseProcedure, CaseImplant } from '../../../case.types'

interface ProceduresCardProps {
  caseId: string
  procedures: CaseProcedure[]
  implants: CaseImplant[]
}

export const ProceduresCard: React.FC<ProceduresCardProps> = ({
  caseId,
  procedures,
  implants,
}) => {
  const [isSelectProceduresOpen, setIsSelectProceduresOpen] = useState<boolean>(
    false
  )

  if (isSelectProceduresOpen) {
    return (
      <SelectProcedures
        close={() => setIsSelectProceduresOpen(false)}
        caseId={caseId}
        implants={implants}
        existingProcedures={procedures.map(
          (procedure) => procedure.procedureId
        )}
        onSuccess={() => setIsSelectProceduresOpen(false)}
      />
    )
  }

  return (
    <Card>
      <CardHeader>
        <Text as="h2" variant="xlarge-heading">
          Procedures
        </Text>
      </CardHeader>

      <PlainList>
        {procedures.map((procedure, i) => (
          <li key={procedure.id}>
            <Procedure {...procedure} index={i} />
          </li>
        ))}
      </PlainList>

      <Button
        variant="secondary"
        label="Update Procedures"
        onClick={() => setIsSelectProceduresOpen(true)}
      />
    </Card>
  )
}
