import React, { useState, useEffect } from 'react'
import { useQuery, useLazyQuery } from '@apollo/client'
import { useNavigate, RouteComponentProps } from '@reach/router'
import { Box, Layer } from 'grommet'
import { AddIcon, ChevronDownIcon, NoDataIcon } from '@orx/ui/dist/icons'
import { ActionButton, Text, Calendar, Button, Spinner } from '@orx/ui/dist'
import { COLOR_VALUES } from '@orx/ui/dist/theme'
import { DateTime } from 'luxon'
import { DashboardHeaderAction } from './header-action'
import { CaseCard, CaseStatusType, Layout } from '../../components'
import {
  dateStringToShortDateTitle,
  dateToShortDate,
} from '@orx/ui/dist/utils/date'
import { GET_USER } from '../../graphql/queries/user'
import { User } from '../../graphql/queries/types/User'
import { GET_CASES_BY_DATE, GET_CASES_DATES } from '../../graphql/queries/cases'
import { caseFilterDate } from '../../store'
import { GetCasesByDate } from '../../graphql/queries/types/GetCasesByDate'
import { GetCasesDates } from '../../graphql/queries/types/GetCasesDates'
import { userVar } from '../../store'

export type DashboardPageProps = RouteComponentProps

export const DashboardPage: React.FC<DashboardPageProps> = () => {
  const navigate = useNavigate()
  const [userInfo, setUserInfo] = useState(userVar())

  // useLazyQuery so we don't keep querying for the user info whenever we hit the the dashboard route
  useEffect(() => {
    if (!userInfo) {
      getUserInfo()
    }
  }, [])

  const [getUserInfo] = useLazyQuery<User>(GET_USER, {
    onCompleted(userData) {
      const userInfo = {
        firstName: userData?.user?.firstName || '',
        lastName: userData?.user?.lastName || '',
        fullName: userData?.user?.fullName || '',
        email: userData?.user?.email || '',
        role: userData?.user?.role || '',
      }
      userVar(userInfo)
      setUserInfo(userVar())
    },
  })

  const { data, loading, error } = useQuery<GetCasesByDate>(GET_CASES_BY_DATE, {
    variables: { date: caseFilterDate() },
    skip: !caseFilterDate(),
  })

  const { data: casesDatesData } = useQuery<GetCasesDates>(GET_CASES_DATES)
  const caseDatesStatues: string[] = (casesDatesData?.cases || []).map(
    (c) => c.procedureDate
  )

  const [isCalendarOpen, setIsCalendarOpen] = useState<boolean>(false)

  const onDateSelect = (select: string) => {
    caseFilterDate(DateTime.fromISO(select).toISO({ includeOffset: true }))
    setIsCalendarOpen(false)
  }

  const cases = (data?.cases || [])
    .filter(({ procedureDate }) =>
      DateTime.fromISO(procedureDate).hasSame(
        DateTime.fromISO(caseFilterDate()),
        'day'
      )
    )
    .sort((a, b) => a.procedureDate - b.procedureDate)

  if (error || loading) {
    return (
      <Layout HeaderAction={DashboardHeaderAction} subHeader={<Box fill />}>
        <Box
          as="main"
          flex="grow"
          background="champagne"
          pad="medium"
          gap="medium"
        >
          {error && <Text margin="auto">{error.message}</Text>}
          {loading && (
            <Box flex="grow" justify="center" align="center">
              <Spinner />
              <Text margin={{ top: 'small' }}>Loading...</Text>
            </Box>
          )}
        </Box>
      </Layout>
    )
  }

  return (
    <>
      <Layout
        HeaderAction={DashboardHeaderAction}
        subHeader={
          userVar()?.fullName ? (
            <Text variant="body-copy" margin={{ horizontal: 'auto' }}>
              {userVar()?.fullName}’s Dashboard
            </Text>
          ) : undefined
        }
      >
        <Box
          as="main"
          flex="grow"
          background="champagne"
          pad="medium"
          gap="medium"
        >
          <Box direction="row" justify="between" align="baseline">
            <Text variant="body-copy">Viewing Cases For:</Text>
            <Box
              align="center"
              direction="row"
              onClick={() => setIsCalendarOpen(true)}
            >
              <Text variant="body-copy">
                {caseFilterDate
                  ? dateToShortDate(caseFilterDate())
                  : 'Choose date'}
              </Text>
              <ActionButton
                size="medium"
                variant="transparent"
                icon={<ChevronDownIcon color="orx-green" />}
              />
            </Box>
          </Box>
          {cases.length === 0 ? (
            <Box flex="grow" gap="large" margin="auto" alignContent="center">
              <Box
                width="288px"
                height="288px"
                align="center"
                justify="center"
                alignSelf="center"
                round="full"
                background={{ color: COLOR_VALUES['champagne-dark'] }}
              >
                <NoDataIcon size="140px" color="orx-purple" />
              </Box>
              <Text variant="xlarge-heading" textAlign="center">
                You currently have no cases.
              </Text>
              <Box flex={false} align="center">
                <Button
                  variant="primary"
                  label="Build a New Case"
                  icon={<AddIcon size="small" color="white" />}
                  onClick={() => navigate('/qc-wizard')}
                />
              </Box>
            </Box>
          ) : (
            cases.map((caseItem) => (
              <CaseCard
                key={caseItem.id}
                caseId={caseItem.id}
                title={dateStringToShortDateTitle(caseItem.procedureDate)}
                caseStatus={caseItem.status as CaseStatusType}
                facility={caseItem.facility?.name || '-'}
                surgeon={caseItem.surgeon?.fullName || '-'}
                procedures={(caseItem.caseProcedures || []).map(
                  (procedure) => procedure.procedure.name
                )}
              />
            ))
          )}
        </Box>
      </Layout>
      {isCalendarOpen && (
        <Layer
          position="center"
          modal
          full="horizontal"
          onClickOutside={() => setIsCalendarOpen(false)}
          onEsc={() => setIsCalendarOpen(false)}
          responsive={false}
        >
          <Box height="29rem" width="26rem" pad="medium" margin="auto">
            <Calendar
              date={caseFilterDate()}
              onSelect={onDateSelect as (select: string | string[]) => void}
              caseDates={caseDatesStatues}
            />
          </Box>
        </Layer>
      )}
    </>
  )
}

export default DashboardPage
