import React, { useState } from 'react'
import { Box } from 'grommet'
import { ActionButton, Heading, Text } from '@orx/ui/dist'
import { ChevronDownIcon, ChevronUpIcon } from '@orx/ui/dist/icons'
import { CaseImplant, ExtraItem } from '../../../case.types'

interface Props {
  implants: CaseImplant[]
  extraItems: ExtraItem[]
}

export const PostSurgeryPriceCard: React.FC<Props> = ({
  implants,
  extraItems,
}) => {
  const [expanded, setExpanded] = useState(false)

  const implantUsages = ['implanted', 'opened', 'wasted'] as const
  const implantList = implants
    .reduce((acc, implant) => {
      const usages = []
      for (const usage of implantUsages) {
        if (implant[usage]) {
          usages.push({
            id: implant.id,
            implantName: implant.implantName,
            cost: implant[usage] * implant.extendedPrice[usage],
            quantity: implant[usage],
            usage,
          })
        }
      }

      return [...acc, ...usages]
    }, [])
    .sort(
      (a, b) => a.implantName.localeCompare(b.implantName) || b.cost - a.cost
    )

  const totalImplants = implants.reduce(
    (tot, implant) =>
      (tot += implant.implanted + implant.opened + implant.wasted),
    0
  )

  const implantTotal = implantList.reduce(
    (tot, implant) => (tot += implant.cost),
    0
  )

  const extraItemTotal = extraItems.reduce(
    (tot, item) => (tot += item.price),
    0
  )

  const totalCost = implantTotal + extraItemTotal

  return (
    <Box
      flex="grow"
      fill="horizontal"
      pad="medium"
      round="small"
      elevation="xsmall"
      background="white"
    >
      <Box
        direction="row"
        justify="between"
        pad={{ bottom: 'small' }}
        margin={{ bottom: 'small' }}
        border={{ side: 'bottom', size: 'small', color: 'ice-grey' }}
      >
        <Heading level={2}>Bill Summary</Heading>
        <ActionButton
          onClick={() => setExpanded(!expanded)}
          icon={!expanded ? <ChevronDownIcon /> : <ChevronUpIcon />}
          size="small"
        />
      </Box>
      <Box gap="xsmall">
        {totalImplants > 0 && (
          <>
            <Box direction="row" justify="between">
              <Text color="orx-grey">
                Implants {!expanded && `(${totalImplants})`}:
              </Text>
              {!expanded && (
                <Text color="orx-grey">
                  $
                  {implantTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Text>
              )}
            </Box>
            {expanded && (
              <Box>
                {implantList.map((implant) => (
                  <Box
                    key={`${implant.usage}-${implant.id}`}
                    direction="row"
                    justify="between"
                  >
                    <Text color="orx-grey" variant="body-copy-small">
                      {implant.implantName} x {implant.quantity}
                    </Text>
                    <Text color="orx-grey" variant="body-copy-small">
                      $
                      {implant.cost.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </Text>
                  </Box>
                ))}
                <Box direction="row" justify="between">
                  <Text color="orx-grey" variant="body-copy-small">
                    Implant Subtotal:
                  </Text>
                  <Text color="orx-grey" variant="body-copy-small">
                    $
                    {implantTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Text>
                </Box>
              </Box>
            )}
          </>
        )}

        {extraItems.length > 0 && (
          <>
            <Box direction="row" justify="between">
              <Text color="orx-grey">
                Extra Items {!expanded && `(${extraItems.length})`}:
              </Text>
              {!expanded && (
                <Text color="orx-grey">
                  $
                  {extraItemTotal.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}
                </Text>
              )}
            </Box>
            {expanded && (
              <Box>
                {extraItems.map((item) => (
                  <Box key={item.id} direction="row" justify="between">
                    <Text color="orx-grey" variant="body-copy-small">
                      {item.name}
                    </Text>
                    <Text color="orx-grey" variant="body-copy-small">
                      $
                      {item.price.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })}
                    </Text>
                  </Box>
                ))}
                <Box direction="row" justify="between">
                  <Text color="orx-grey" variant="body-copy-small">
                    Extra Items Subtotal:
                  </Text>
                  <Text color="orx-grey" variant="body-copy-small">
                    $
                    {extraItemTotal.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}
                  </Text>
                </Box>
              </Box>
            )}
          </>
        )}

        <Box direction="row" justify="between">
          <Text>Total:</Text>
          <Text>
            $
            {totalCost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </Text>
        </Box>
      </Box>
    </Box>
  )
}
