import { gql } from '@apollo/client'

export const GET_QCW_VENDOR_LIST = gql`
  query VendorList($where: String) {
    qcwFilter @client @export(as: "where")
    vendors(vendorSearch: { where: $where }) {
      id
      name
      address {
        city
        state
      }
    }
  }
`
