import React, { useEffect, useState } from 'react'
import { Box } from 'grommet'
import { Spinner } from '@orx/ui/dist'
import { ApolloProvider } from '@apollo/client'
import { Button, Text } from '@orx/ui/dist'
import { useAuth0 } from '@auth0/auth0-react'
import './fonts/fonts.css'
import { bearerToken } from './store'
import { ApolloClient, NormalizedCacheObject, HttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { createCache } from './apollo/cache'

const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL })

export const ApolloWrapper: React.FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState('')

  const {
    isAuthenticated,
    isLoading: auth0Loading,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0()

  const getToken = async () => {
    try {
      const token = await getAccessTokenSilently()
      setAccessToken(token)
      bearerToken(token) // set token on global variable for axios rest client
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (isAuthenticated) {
      getToken()
    }
  }, [isAuthenticated])

  const authLink = setContext(async (_, { headers, ...rest }) => {
    return {
      ...rest,
      headers: {
        ...headers,
        authorization: `Bearer ${accessToken}`,
        connectToDevTools: process.env.NODE_ENV === 'development',
      },
    }
  })

  const client = new ApolloClient({
    cache: createCache(),
    link: authLink.concat(httpLink),
    connectToDevTools: process.env.NODE_ENV === 'development',
  })

  if (auth0Loading) {
    return (
      <Box fill background="champagne" align="center" justify="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!isAuthenticated) {
    return (
      <Box fill background="champagne" align="center" justify="center">
        <Text margin="medium">Not Authorized.</Text>
        <Button
          onClick={() => loginWithRedirect()}
          variant="primary"
          size="small"
          label="Login"
        />
      </Box>
    )
  }

  return (
    <ApolloProvider client={client as ApolloClient<NormalizedCacheObject>}>
      {children}
    </ApolloProvider>
  )
}
