import React from 'react'
import { Box } from 'grommet'
import {
  Card,
  CardHeader,
  CardFooter,
  IconButton,
  Heading,
  Text,
} from '@orx/ui/dist'
import { EditIcon } from '@orx/ui/dist/icons'
import { Counts } from './billing-details'
import { Address } from '../../../case.types'
import styled from 'styled-components'
import { COLOR_VALUES } from '@orx/ui/dist/theme'

const AddressFont = styled(Text)`
  font-weight: 400;
`

const ImplantCount = styled.span`
  color: ${COLOR_VALUES['orx-green']};
`

interface Props {
  variant: 'facility' | 'vendor' | 'rep'
  vendorNumber?: number
  name: string
  address: Address
  email?: string
  phone?: string
  onActionButtonClick?: () => void
  restockCounts?: Counts
}

export const PostSurgeryCard: React.FC<Props> = ({
  variant,
  vendorNumber,
  name,
  email,
  address,
  phone,
  onActionButtonClick = () => undefined,
  restockCounts,
}) => {
  let cardTitle
  if (variant === 'facility') {
    cardTitle = 'Bill To'
  } else if (variant === 'vendor') {
    cardTitle = 'Vendor Info'
  } else {
    cardTitle = 'Rep Info'
  }

  let restock: (JSX.Element | null)[] = []
  if (restockCounts) {
    restock = Object.keys(restockCounts || [])
      .map((vendorName) => {
        if (
          variant === 'vendor' &&
          vendorName === name &&
          restockCounts[vendorName][variant] > 0
        ) {
          return (
            <Text key={vendorName}>
              <ImplantCount>{restockCounts[vendorName][variant]}</ImplantCount>{' '}
              Implants will be restocked here
            </Text>
          )
        }

        if (variant !== 'vendor' && restockCounts[vendorName][variant] > 0) {
          return (
            <Text key={vendorName}>
              <ImplantCount>{restockCounts[vendorName][variant]}</ImplantCount>{' '}
              {vendorName} Implants will be restocked here
            </Text>
          )
        }

        return null
      })
      .filter((el) => el)
  }

  return (
    <Card>
      <CardHeader hasDivider>
        <Box
          direction="row"
          justify="between"
          align="center"
          margin={{ bottom: 'xsmall' }}
        >
          {vendorNumber && (
            <Text
              variant="body-copy-small"
              color={{ light: 'steel-grey', dark: 'white' }}
              margin={{ right: 'xsmall' }}
            >
              #{vendorNumber}
            </Text>
          )}
          <Heading level={2}>{cardTitle}</Heading>
          <Box margin="auto" />
          {/* this below will be implemented in future version of the app */}
          {false && (
            <IconButton
              icon={<EditIcon />}
              a11yTitle="Edit Implant"
              size="medium"
              color="orx-green"
              onClick={onActionButtonClick}
            />
          )}
        </Box>
      </CardHeader>
      <Box pad={{ vertical: 'xsmall' }}>
        <Text margin={{ bottom: 'xsmall' }}>{name}</Text>
        <AddressFont>{address?.street}</AddressFont>
        <AddressFont>
          {address?.city ? `${address?.city},` : ''} {address?.state}{' '}
          {address?.zipCode}
        </AddressFont>
        {phone && <AddressFont>{phone}</AddressFont>}
        <AddressFont>{email}</AddressFont>
      </Box>
      {restock.length > 0 && (
        <CardFooter hasDivider>
          <Box gap="xsmall">{restock}</Box>
        </CardFooter>
      )}
    </Card>
  )
}
