import React, { useState } from 'react'
import { Box } from 'grommet'
import { ActionButton, IconButton, Text } from '@orx/ui/dist'
import { EditIcon, ChevronDownIcon, ChevronUpIcon } from '@orx/ui/dist/icons'
import { Sizes } from '../../pages/case/components/add-implant/add-implant.types'
import { DateTime } from 'luxon'
import styled from 'styled-components'

const Capatalize = styled.span`
  text-transform: capitalize;
`

interface Props {
  variant: 'implanted' | 'opened' | 'wasted'
  implantNumber: number
  actionButtonDisabled?: boolean
  onActionButtonClick?: () => void
  implantName: string
  implantDescription: string
  implantSizes: Sizes[] | null
  implantUsage: number
  referenceNumber: string
  lotNumber: string
  expiryDate: string | null
}

export const CaseImplantCard: React.FC<Props> = ({
  variant,
  actionButtonDisabled = false,
  implantNumber,
  onActionButtonClick,
  implantName,
  implantDescription,
  implantUsage,
  expiryDate,
  lotNumber,
  referenceNumber,
}) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <Box
      flex="grow"
      fill="horizontal"
      pad="medium"
      round="small"
      elevation="xsmall"
      background="white"
    >
      <Box
        flex="grow"
        direction="row"
        gap="small"
        justify="between"
        align="start"
        margin={{ bottom: 'xsmall' }}
      >
        <Box width="large">
          <Text
            variant="body-copy-small"
            color={{ light: 'steel-grey', dark: 'white' }}
          >
            Implant# {implantNumber}
          </Text>
          <Box gap="xsmall">
            <Text>{implantName}</Text>
            {implantDescription && (
              <Text variant="body-copy-small" color="steel-grey">
                {implantDescription}
              </Text>
            )}
          </Box>
        </Box>

        {!expanded && (
          <Box width="medium" align="end">
            <Text
              variant="body-copy-small"
              color={{ light: 'steel-grey', dark: 'white' }}
            >
              QTY
            </Text>
            <Text variant="body-copy-small">{implantUsage}</Text>
          </Box>
        )}

        <Box width="medium" align="end">
          {!expanded ? (
            <ActionButton
              onClick={() => setExpanded(true)}
              icon={<ChevronDownIcon />}
              size="small"
            />
          ) : (
            onActionButtonClick !== undefined && (
              <IconButton
                icon={<EditIcon />}
                a11yTitle="Edit Implant"
                size="medium"
                color={actionButtonDisabled ? 'steel-grey' : 'orx-green'}
                disabled={actionButtonDisabled}
                onClick={onActionButtonClick}
              />
            )
          )}
        </Box>
      </Box>
      {expanded && (
        <>
          <Box gap="small">
            <Box
              direction="row"
              gap="small"
              pad={{ vertical: 'xsmall' }}
              border={{ side: 'horizontal', size: '1px', color: 'ice-grey' }}
            >
              <Box fill="horizontal">
                <Text
                  variant="body-copy-small"
                  color={{ light: 'steel-grey', dark: 'white' }}
                >
                  LOT
                </Text>
                <Text variant="body-copy-small" wordBreak="break-word">
                  {!lotNumber ? '—' : lotNumber}
                </Text>
              </Box>
              <Box fill="horizontal">
                <Text
                  variant="body-copy-small"
                  color={{ light: 'steel-grey', dark: 'white' }}
                >
                  REF
                </Text>
                <Text variant="body-copy-small">{referenceNumber}</Text>
              </Box>
            </Box>
            <Box direction="row" gap="small">
              <Box fill="horizontal">
                <Text
                  variant="body-copy-small"
                  color={{ light: 'steel-grey', dark: 'white' }}
                >
                  Usage
                </Text>
                <Text variant="body-copy-small">
                  {implantUsage} <Capatalize>{variant}</Capatalize>
                </Text>
              </Box>
              <Box fill="horizontal">
                <Text
                  variant="body-copy-small"
                  color={{ light: 'steel-grey', dark: 'white' }}
                >
                  Expiry
                </Text>
                <Text variant="body-copy-small">
                  {!expiryDate
                    ? '—'
                    : DateTime.fromISO(expiryDate).toLocaleString()}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box align="end">
            <ActionButton
              onClick={() => setExpanded(false)}
              icon={<ChevronUpIcon />}
              size="small"
            />
          </Box>
        </>
      )}
    </Box>
  )
}
