import { gql } from '@apollo/client'

export const GET_CASES_DASHBOARD = gql`
  query GetCases {
    cases {
      id
      procedureDate
      surgeon {
        fullName
      }
      facility {
        name
      }
      status
      caseProcedures {
        procedure {
          name
        }
      }
    }
  }
`
export const GET_CASES_DATES = gql`
  query GetCasesDates {
    cases {
      id
      procedureDate
    }
  }
`

export const CASE_DASHBOARD_FRAGMENT = gql`
  fragment CaseDashboard on Case {
    id
    procedureDate
    surgeon {
      fullName
    }
    facility {
      name
    }
    status
    caseProcedures {
      procedure {
        name
      }
    }
  }
`

export const GET_CASES_BY_DATE = gql`
  query GetCasesByDate($date: String!) {
    cases(date: $date) {
      ...CaseDashboard
    }
  }
  ${CASE_DASHBOARD_FRAGMENT}
`
export const GET_DASHBOARD_CASES = gql`
  query GetDashboardCases($date: String) {
    caseFilterDate @client @export(as: "date")
    cases(date: $date) {
      ...CaseDashboard
    }
  }
  ${CASE_DASHBOARD_FRAGMENT}
`
