export const dateStringToDate = (dateString: string): Date => {
  return new Date(dateString)
}

export const ordinalSuffixOf = (str: string): string => {
  const i = parseInt(str, 10)
  const j = i % 10
  const k = i % 100
  if (j === 1 && k !== 11) {
    return i + 'st'
  }
  if (j === 2 && k !== 12) {
    return i + 'nd'
  }
  if (j === 3 && k !== 13) {
    return i + 'rd'
  }
  return i + 'th'
}

export const dateStringToShortDateTitle = (dateString: string): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: hour12 },
  ] = dateTimeFormat.formatToParts(dateStringToDate(dateString))
  return `${month} ${day} at ${hour}:${minute}${hour12}`
}

// October 1st, 2020
export const dateToShortDate = (date: string | Date): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
  ] = dateTimeFormat.formatToParts(
    typeof date === 'string' ? dateStringToDate(date) : date
  )
  return `${month} ${ordinalSuffixOf(day)}, ${year}`
}

// Sep 14th, 2020, 10:00 AM
export const dateToShortDateTime = (date: string | Date): string => {
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
  const [
    { value: month },
    ,
    { value: day },
    ,
    { value: year },
    ,
    { value: hour },
    ,
    { value: minute },
    ,
    { value: dayPeriod },
  ] = dateTimeFormat.formatToParts(
    typeof date === 'string' ? dateStringToDate(date) : date
  )
  return `${month} ${ordinalSuffixOf(
    day
  )}, ${year}, ${hour}:${minute} ${dayPeriod}`
}

export const datesAreOnSameDay = (
  firstDate: string | Date,
  secondDate: string | Date
): boolean => {
  const first =
    typeof firstDate === 'string' ? dateStringToDate(firstDate) : firstDate
  const second =
    typeof secondDate === 'string' ? dateStringToDate(secondDate) : secondDate
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate()
  )
}
export const datesAreOnSameMonth = (
  firstDate: string | Date,
  secondDate: string | Date
): boolean => {
  const first =
    typeof firstDate === 'string' ? dateStringToDate(firstDate) : firstDate
  const second =
    typeof secondDate === 'string' ? dateStringToDate(secondDate) : secondDate
  return (
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth()
  )
}

export const splitDateTime = (dateTimeInput: string | Date): string[] => {
  const dateTime =
    typeof dateTimeInput === 'string'
      ? dateStringToDate(dateTimeInput)
      : dateTimeInput
  const dateTimeFormat = new Intl.DateTimeFormat('en', {
    hour12: true,
    hour: 'numeric',
    minute: 'numeric',
  })
  const [
    { value: hour },
    ,
    { value: minute },
    ,
    { value: dayPeriod },
  ] = dateTimeFormat.formatToParts(dateTime)
  return [dateTime.toDateString(), `${hour}:${minute} ${dayPeriod}`]
}

export const splitTime = (
  timeString: string
): { hours: number; minutes: number } | null => {
  // HH:MM AP
  const [hoursString, minutesString, apString] = timeString.split(/:| /)

  if (hoursString && minutesString && apString) {
    let hours = parseInt(hoursString)
    if (hoursString === '12') {
      hours = 0
    }

    if (apString.toLocaleLowerCase() === 'pm') {
      hours += 12
    }

    return {
      hours,
      minutes: parseInt(minutesString),
    }
  }

  return null
}
