import React, { useReducer } from 'react'
import { Box } from 'grommet'
import { useNavigate } from '@reach/router'
import {
  Header,
  Text,
  TextInput,
  StepIndicator,
  IconButton,
} from '@orx/ui/dist'
import { Layout } from '../../components'
import { SummaryBar } from './components/summary-bar'
import { ACTION_BAR_HEIGHT } from '../../components/accordion-action-bar'
import { SearchIcon, ActionArrowLeftIcon } from '@orx/ui/dist/icons'
import { ProcedureStep } from './components/procedure-step'
import { SurgeonStep } from './components/surgeon-step'
import { QuickCaseWizardPageProps } from './quick-case-wizard.page.types'
import { FacilityStep } from './components/facility-step'
import {
  quickCaseWizardReducer,
  quickCaseWizardInitialState,
  nextStep,
  goToStep,
  setProcedures,
  setFacility,
  setFilter,
  setSurgeon,
  setVendors,
} from './quick-case-wizard.page.reducer'
import { VendorStep } from './components/vendor-step'
import { ConfirmationStep } from './components/confirmation-step'
import { ConfirmationButtons } from './components/confirmation-buttons'
import styled from 'styled-components'
import { caseFilterDate } from '../../store'

const FixedBox = styled(Box)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
`

export const QuickCaseWizardPage: React.FC<QuickCaseWizardPageProps> = () => {
  const navigate = useNavigate()

  const [state, dispatch] = useReducer(
    quickCaseWizardReducer,
    quickCaseWizardInitialState(caseFilterDate())
  )
  const isConfirmationStep = state.step === 5

  function gotoEnd() {
    dispatch({
      type: 'GOTO_STEP',
      step: 5,
    })
  }

  function handleBackButton() {
    if (state.isEditingStep && state.step < 5) gotoEnd()
    else if (state.step > 1) {
      dispatch({ type: 'END_EDITING' })
      dispatch(goToStep(state.step - 1))
    } else navigate('/')
  }

  const procedureAt = new Date(state.surgeryTime)

  function handleSummaryBarContinue() {
    if (state.isEditingStep) gotoEnd()
    else dispatch(nextStep)
  }

  return (
    <Layout
      header={
        <Header>
          <IconButton
            a11yTitle="back"
            onClick={handleBackButton}
            icon={<ActionArrowLeftIcon />}
            size="small"
            margin={{ right: 'small' }}
          />
          <Text variant="body-copy">{state.title}</Text>
          <Box margin="auto" />
          <StepIndicator step={state.step} of={5} />
        </Header>
      }
      subHeader={
        isConfirmationStep ? (
          <Text variant="body-copy-small">
            Confirm Case information below before starting a case.
          </Text>
        ) : (
          <Box round="xsmall" background="white" fill="horizontal">
            <TextInput
              plain
              reverse
              value={state.filter}
              placeholder={state.placeholder}
              icon={<SearchIcon size="small" color="orx-green" />}
              onChange={(event) => {
                dispatch(setFilter(event.target.value))
              }}
            />
          </Box>
        )
      }
    >
      <Box
        as="main"
        flex="grow"
        background="champagne"
        // height of the fixed bottom bar
        margin={{ bottom: ACTION_BAR_HEIGHT }}
      >
        {state.step === 1 && (
          <ProcedureStep
            selectedProcedures={state.wizardData.procedures}
            onSelectedChanged={(selectedProcedures) =>
              dispatch(setProcedures(selectedProcedures))
            }
          />
        )}
        {state.step === 2 && (
          <FacilityStep
            selectedFacilityId={state.wizardData.facility?.id || ''}
            onSelectedChanged={(selectedFacility) =>
              dispatch(setFacility(selectedFacility))
            }
          />
        )}
        {state.step === 3 && (
          <SurgeonStep
            selectedSurgeonId={state.wizardData.surgeon?.id || ''}
            onSelectedChanged={(selectedFacility) =>
              dispatch(setSurgeon(selectedFacility))
            }
          />
        )}
        {state.step === 4 && (
          <VendorStep
            selectedVendors={state.wizardData.vendors}
            onSelectedChanged={(selectedVendors) =>
              dispatch(setVendors(selectedVendors))
            }
          />
        )}
        {state.step === 5 && (
          <ConfirmationStep
            confirmationList={state.summaryList}
            onAction={(action) => dispatch(action)}
            surgeryTime={state.surgeryTime}
          />
        )}
      </Box>
      <FixedBox as="footer">
        {!isConfirmationStep ? (
          <SummaryBar
            onContinue={handleSummaryBarContinue}
            canContinue={state.canContinue}
            summaryList={state.summaryList}
          />
        ) : (
          <ConfirmationButtons
            facilityId={state.wizardData.facility?.id as string}
            surgeonId={state.wizardData.surgeon?.id as string}
            procedureDate={procedureAt}
            vendors={state.wizardData.vendors.map((a) => a.id)}
            procedures={state.wizardData.procedures.map((a) => a.id)}
          />
        )}
      </FixedBox>
    </Layout>
  )
}

export default QuickCaseWizardPage
