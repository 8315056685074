import React, { useState } from 'react'
import {
  Card,
  CardHeader,
  Text,
  TextInputField,
  TextAreaField,
} from '@orx/ui/dist'
import { Form } from 'grommet'
import { useUpdateCaseVendor } from '../use-update-case-vendor'

interface MiscCardProps {
  caseVendorId: string
  externalVendorCaseId?: string
  vendorNote?: string
  title: string
}

export const MiscCard: React.FC<MiscCardProps> = ({
  caseVendorId,
  externalVendorCaseId,
  vendorNote,
  title,
}) => {
  const [caseId, setCaseId] = useState(externalVendorCaseId || '')
  const [note, setNote] = useState(vendorNote || '')
  const { updateCaseVendor, isUpdatingCaseVendor } = useUpdateCaseVendor(
    caseVendorId
  )

  return (
    <Card>
      <CardHeader hasDivider>
        <Text variant="xlarge-heading">{title}</Text>
      </CardHeader>
      <Form
        value={{
          externalVendorCaseId: caseId,
          vendorNote: note,
        }}
      >
        <TextInputField
          name="externalVendorCaseId"
          label="Vendor Case ID (Optional)"
          disabled={isUpdatingCaseVendor}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCaseId(e.target.value)
          }
          onBlur={() => {
            if (caseId !== externalVendorCaseId) {
              updateCaseVendor({ externalVendorCaseId: caseId })
            }
          }}
        />
        <TextAreaField
          full
          name="vendorNote"
          label="Vendor notes (Optional)"
          disabled={isUpdatingCaseVendor}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            setNote(e.target.value)
          }
          onBlur={() => {
            const trimmedNote = note.trim()
            if (trimmedNote !== vendorNote) {
              updateCaseVendor({ vendorNote: trimmedNote })
            }
          }}
        />
      </Form>
    </Card>
  )
}
