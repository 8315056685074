import React, { useState, useEffect, useRef } from 'react'
import { Box, Collapsible } from 'grommet'
import { ActionButton, Text } from '@orx/ui/dist'
import { ChevronDownIcon, ChevronUpIcon } from '@orx/ui/dist/icons'
import styled from 'styled-components'

const BoxShadowBox = styled(Box)`
  box-shadow: 0 -4px 40px rgba(0, 0, 0, 0.16);
`

interface Props {
  actions?: JSX.Element
  title?: string
}

export const ACTION_BAR_HEIGHT = '88px'

export const AccordionActionBar: React.FC<Props> = ({
  title,
  children,
  actions,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const collapsibleNode = useRef<HTMLDivElement>(null)
  const buttonNode = useRef<HTMLDivElement>(null)

  const handleClick = (e: MouseEvent) => {
    if (
      collapsibleNode?.current?.contains(e.target as Node) ||
      buttonNode?.current?.contains(e.target as Node)
    ) {
      return
    }

    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClick)

    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [isOpen])

  return (
    <>
      <Collapsible direction="vertical" open={isOpen} ref={collapsibleNode}>
        {children}
      </Collapsible>
      <BoxShadowBox background="white" height={ACTION_BAR_HEIGHT}>
        <Box direction="row" align="center" gap="small" pad="medium">
          <Box ref={buttonNode}>
            <ActionButton
              a11yTitle={isOpen ? 'Close' : 'Open'}
              size="small"
              icon={isOpen ? <ChevronDownIcon /> : <ChevronUpIcon />}
              onClick={() => setIsOpen(!isOpen)}
            />
          </Box>
          {title && (
            <Box justify="center">
              <Text variant="body-copy">{title}</Text>
            </Box>
          )}
          {actions && (
            <Box width="100%" justify="end">
              {actions}
            </Box>
          )}
        </Box>
      </BoxShadowBox>
    </>
  )
}
