import React from 'react'
import { CaseStatusProps, caseStatusStyleMap } from './case-status.types'
import { StatusLabel } from '@orx/ui/dist'

export const CaseStatus: React.FC<CaseStatusProps> = ({
  caseStatus,
  showLabel = true,
}) => {
  const { color, label } = caseStatusStyleMap[caseStatus]
  return <StatusLabel color={color} label={showLabel ? label : undefined} />
}
