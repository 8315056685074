import React from 'react'
import ReactDOM from 'react-dom'
import { Grommet } from 'grommet'
import '@orx/ui/fonts/fonts.css'
import { ToastMessageProvider } from '@orx/ui/dist'
import { ORX_theme } from '@orx/ui/dist/theme'
import * as serviceWorker from './serviceWorker'
import Pages from './pages'
import { Auth0WrapperWithHistory } from './auth0-wrapper'
import { ApolloWrapper } from './apollo-wrapper'
import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
  }
`

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Grommet theme={ORX_theme}>
      <GlobalStyles />
      <Auth0WrapperWithHistory>
        <ApolloWrapper>
          <ToastMessageProvider>
            <Pages />
          </ToastMessageProvider>
        </ApolloWrapper>
      </Auth0WrapperWithHistory>
    </Grommet>,
    document.getElementById('root')
  )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
