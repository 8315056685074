import React, { Dispatch, ComponentType } from 'react'
import { IconButton, Header, Text } from '@orx/ui/dist'
import { MenuIcon } from '@orx/ui/dist/icons'
import { Box } from 'grommet'

interface DefaultHeaderProps {
  className?: string
  title?: string
  setIsMenuOpen: Dispatch<React.SetStateAction<boolean>>
  Action?: ComponentType
}

export const DefaultHeader: React.FC<DefaultHeaderProps> = ({
  className,
  title,
  setIsMenuOpen,
  Action,
}) => (
  <Header className={className} hasLogo={!title}>
    <IconButton
      a11yTitle="menu"
      icon={<MenuIcon color="white" />}
      onClick={() => setIsMenuOpen((prev) => !prev)}
      margin={{ right: 'small' }}
    />
    {title && <Text>{title}</Text>}
    <Box margin="auto" />
    {Action && <Action />}
  </Header>
)
