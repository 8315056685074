import { gql, InMemoryCache } from '@apollo/client'
import { caseFilterDate, qcwFilter, implantSearch } from '../store'

export const typeDefs = gql`
  extend type Query {
    caseFilterDate: String!
    qcwFilter: String
    implantSearch: String
  }
`

export function createCache(): InMemoryCache {
  return new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          caseFilterDate: {
            read(): string {
              return caseFilterDate()
            },
          },
          qcwFilter: {
            read(): string | null {
              return qcwFilter()
            },
          },
          implantSearch: {
            read(): string | null {
              return implantSearch()
            },
          },
        },
      },
    },
  })
}
