import React, { useState, ChangeEvent } from 'react'
import { useMutation, gql } from '@apollo/client'
import { Text, TextAreaField, useToast } from '@orx/ui/dist'
import { CaseProcedure } from '../../../case.types'

const UPDATE_CASE_PROCEDURE = gql`
  mutation UpdateCaseProcedure(
    $caseProcedureId: ID!
    $values: CaseProcedureUpdate!
  ) {
    updateCaseProcedure(
      id: $caseProcedureId
      updateCaseProcedureData: $values
    ) {
      id
      description
    }
  }
`

interface MutationData {
  updateCaseProcedure: {
    id: string
    description: string
  }
}

interface MutationVariables {
  caseProcedureId: string
  values: {
    description: string
  }
}

interface ProcedureProps extends Omit<CaseProcedure, 'procedureId'> {
  index: number
}

export const Procedure: React.FC<ProcedureProps> = ({
  id,
  name,
  description,
  index,
}) => {
  const toast = useToast()
  const [value, setValue] = useState<string>(description || '')
  const [update, { loading }] = useMutation<MutationData, MutationVariables>(
    UPDATE_CASE_PROCEDURE,
    {
      onCompleted() {
        toast('Description updated.', { variant: 'success' })
      },
      onError() {
        toast('Could not update description.', { variant: 'danger' })
      },
    }
  )

  return (
    <>
      <Text
        as="h3"
        variant="body-copy-small"
        color={{
          light: 'steel-grey',
          dark: 'white',
        }}
        margin={{
          top: 'xsmall',
          bottom: 'xsmall',
        }}
      >
        Procedure {index + 1}
      </Text>

      <Text
        as="p"
        variant="large-heading"
        margin={{
          top: 'xsmall',
          bottom: 'xsmall',
        }}
      >
        {name}
      </Text>

      <TextAreaField
        full
        name={`productDescription[${index}]`}
        label="Description (Optional)"
        disabled={loading}
        value={value}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) =>
          setValue(event.target.value)
        }
        onBlur={() => {
          if (value !== (description === null ? '' : description)) {
            update({
              variables: {
                caseProcedureId: id,
                values: { description: value },
              },
            })
          }
        }}
      />
    </>
  )
}
