import React from 'react'
import { useQuery } from '@apollo/client'
import { Box } from 'grommet'
import { List, Text, Spinner } from '@orx/ui/dist'
import { WizardListItem } from '../wizard-list-item'
import { WizardItem } from '../../quick-case-wizard.page.types'
import { VendorStepProps } from './vendor-step.types'
import { VendorList } from '../../../../graphql/queries/types/VendorList'
import { GET_QCW_VENDOR_LIST } from '../../../../graphql/queries/vendor'

export const VendorStep: React.FC<VendorStepProps> = ({
  onSelectedChanged,
  selectedVendors,
}) => {
  const { data, loading, error } = useQuery<VendorList>(GET_QCW_VENDOR_LIST)

  const addVendor = (id: string, name: string, address: string): void => {
    const vendors: WizardItem[] = [{ id, name, address }, ...selectedVendors]
    onSelectedChanged(vendors)
  }
  const removeVendor = (id: string): void => {
    const vendors = selectedVendors.filter((value) => value.id !== id)
    onSelectedChanged(vendors)
  }

  const vendors = data?.vendors?.map(({ id, name, address }) => ({
    id,
    name,
    address: `${address?.city}, ${address?.state}`,
    isSelected: selectedVendors.find((value) => value.id === id),
  }))

  if (error) return <Text margin="auto">{error.message}</Text>

  if (loading) {
    return (
      <Box flex="grow" align="center" justify="center">
        <Spinner />
        <Text margin={{ top: 'small' }}>Loading...</Text>
      </Box>
    )
  }

  if (!vendors || vendors.length == 0) {
    return <Text margin="auto">No vendors found.</Text>
  }

  return (
    <List data={vendors}>
      {({ id, name, address, isSelected }) => (
        <WizardListItem
          name={name}
          address={address}
          buttonText="Add"
          buttonSelectedText="Added"
          isSelected={isSelected}
          onSelect={() => {
            isSelected ? removeVendor(id) : addVendor(id, name, address)
          }}
        />
      )}
    </List>
  )
}
