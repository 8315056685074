import { useQuery, gql, ApolloError } from '@apollo/client'
import { Implant, ImplantPrice } from './add-implant.types'

export const IMPLANT_PRICE_QUERY = gql`
  query ImplantPrice($priceSearch: ImplantPriceSearch) {
    implantPrice(implantPriceSearch: $priceSearch) {
      id
      price
      implant {
        id
        externalImplantId
        name
        refId
        productFamily
        vendor {
          name
        }
      }
    }
  }
`

export interface ImplantPriceQueryData {
  readonly id: string
  price: number
  implant: Implant
}

interface QueryData {
  implantPrice: ImplantPriceQueryData
}

interface QueryVariables {
  priceSearch: {
    ImplantIdFacilityId: {
      ImplantId: string
      FacilityId: string
    }
  }
}

type ImplantPriceData = ImplantPrice | null

export function useImplantPriceQuery(
  ImplantId: string,
  FacilityId: string
): {
  implantPriceData: ImplantPriceData
  loading: boolean
  error?: ApolloError
} {
  const { data, loading, error } = useQuery<QueryData, QueryVariables>(
    IMPLANT_PRICE_QUERY,
    {
      variables: {
        priceSearch: {
          ImplantIdFacilityId: {
            ImplantId,
            FacilityId,
          },
        },
      },
    }
  )

  const implantPriceData = !data?.implantPrice
    ? null
    : {
        id: data.implantPrice.id,
        name: data.implantPrice.implant.name,
        vendor: data.implantPrice.implant.vendor.name,
        price: data.implantPrice.price,
        ref: data.implantPrice.implant.refId,
      }

  return { implantPriceData, loading, error }
}
