import React from 'react'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Layer, Avatar, Button as GrommetButton } from 'grommet'
import { Text } from '@orx/ui/dist'
import { ArrowCircleRight, DashboardIcon } from '@orx/ui/dist/icons'
import { SidebarButton } from './side-bar-button'
import { navigate } from '@reach/router'
import { userVar } from '../../store'

const Container = styled(Layer)`
  z-index: 100;
  width: 75%;
`

interface LayoutSideBarProps {
  closeMenu: () => void
}

export const LayoutSideBar: React.FC<LayoutSideBarProps> = ({ closeMenu }) => {
  const { user, logout } = useAuth0()

  function handleLogout() {
    logout({ returnTo: window.location.origin })
    closeMenu()
  }

  return (
    <Container
      modal
      responsive={false}
      position="left"
      full="vertical"
      onClickOutside={closeMenu}
      onEsc={closeMenu}
    >
      <Box background="orx-purple" direction="row" pad="medium" gap="small">
        <Avatar
          flex={false}
          src={user?.picture}
          size="small"
          border={{ size: 'small', color: 'orx-green' }}
        />
        <Box>
          <Text truncate margin={{ vertical: 'auto' }}>
            {userVar()?.fullName}
          </Text>
          <Text
            truncate
            margin={{ vertical: 'auto' }}
            variant="body-copy-xsmall"
          >
            {userVar()?.role}
          </Text>
        </Box>
      </Box>
      <Box as="nav" fill>
        <SidebarButton
          label="Dashboard"
          icon={<DashboardIcon color="orx-green" />}
          onClick={() => navigate('/')}
        />
        <Box flex="grow" justify="end">
          <GrommetButton plain as="a" onClick={handleLogout}>
            <Box
              direction="row"
              align="center"
              justify="start"
              gap="small"
              pad={{ horizontal: 'medium', vertical: 'large' }}
            >
              <ArrowCircleRight color="orx-green" />
              <Text margin={{ vertical: 'auto' }}>Logout</Text>
            </Box>
          </GrommetButton>
        </Box>
      </Box>
    </Container>
  )
}
