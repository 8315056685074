import React, { useState, ChangeEvent, cloneElement } from 'react'
import { Box } from 'grommet'
import {
  Header,
  IconButton,
  ActionButton,
  DateInputField,
  Text,
  TextInputField,
  List,
  DescriptionListItem,
  StepperField,
  RadioButtonGroupField,
} from '@orx/ui/dist'
import { ActionArrowLeftIcon, CloseIcon, EditIcon } from '@orx/ui/dist/icons'
import { Layout } from '../../../../../components/layout'
import { Formik, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import { CaseProcedure } from '../../../case.types'
import { SelectProcedure } from './select-procedure'
import styled from 'styled-components'

const Form = styled.form`
  margin: 0;
`

interface Props {
  caseProcedures: CaseProcedure[]
  procedureDate: Date
  implantProcedure?: {
    caseProcedureId: string
    procedureId: string
  }
  closeDetails: () => void
  implantDetailsList: DescriptionListItem[]
  implantLot?: string
  implantExp?: string | null
  implanted?: number
  wasted?: number
  opened?: number
  restock?: string
  handleSubmit: (
    values: FormikValues,
    CaseProcedureId: string,
    action: 'primary' | 'secondary'
  ) => void
  primaryAction: JSX.Element
  secondaryAction: JSX.Element
  variant?: 'new' | 'edit'
}

export interface FormikValues {
  implantLot: string
  implantExp: string | null
  implanted: number
  wasted: number
  opened: number
  restock: string
}

const validationSchema: Yup.SchemaOf<FormikValues> = Yup.object()
  .shape({
    implantLot: Yup.string().optional().ensure().defined(),
    implantExp: Yup.date().typeError('Invalid Date').optional().nullable(),
    implanted: Yup.number()
      .required()
      .min(0)
      .defined()
      .test('noZeros', 'This is a required field', function (value) {
        return !(
          value === 0 &&
          this.parent.wasted === 0 &&
          this.parent.opened === 0
        )
      }),
    wasted: Yup.number()
      .required()
      .min(0)
      .defined()
      .test('noZeros', 'This is a required field', function (value) {
        return !(
          value === 0 &&
          this.parent.implanted === 0 &&
          this.parent.opened === 0
        )
      }),
    opened: Yup.number()
      .required()
      .min(0)
      .defined()
      .test('noZeros', 'This is a required field', function (value) {
        return !(
          value === 0 &&
          this.parent.wasted === 0 &&
          this.parent.implanted === 0
        )
      }),
    restock: Yup.string().required().defined(),
  })
  .required()
  .defined()

type StepperCB = (x: number) => void

const IMPLANT_ACTIONS = [
  'Finish & Add Another',
  'Finish',
  'Delete Implant',
  'Save & Close',
] as const

type ImplantActions = typeof IMPLANT_ACTIONS[number]

export const IMPLANT_ACTION_VALUES: Record<ImplantActions, string> = {
  'Finish & Add Another': 'Finish & Add Another',
  Finish: 'Finish',
  'Delete Implant': 'Delete Implant',
  'Save & Close': 'Save & Close',
}

export const restockValues = ['No Restock', 'Rep Storage', 'Facility', 'Other']

export const ImplantDetails: React.FC<Props> = ({
  caseProcedures,
  procedureDate,
  implantProcedure = undefined,
  closeDetails,
  implantDetailsList,
  implantLot = '',
  implantExp = null,
  implanted = 0,
  wasted = 0,
  opened = 0,
  restock = 'No Restock',
  handleSubmit,
  primaryAction,
  secondaryAction,
  variant = 'new',
}) => {
  const [selectedProcedure, setSelectedProcedure] = useState<CaseProcedure>(
    (implantProcedure &&
      caseProcedures.find(
        (procedure) => procedure.id === implantProcedure.caseProcedureId
      )) ||
      caseProcedures[0]
  )
  const [showSelectProcedure, setShowSelectProcedure] = useState(false)
  const [implantAction, setImplantAction] = useState<
    'primary' | 'secondary' | null
  >(null)

  // track whether procedure has been updated
  const selectedProcedureUpdated =
    variant === 'edit' &&
    selectedProcedure.id !== implantProcedure?.caseProcedureId

  if (showSelectProcedure) {
    return (
      <SelectProcedure
        closeSelectProcedure={() => setShowSelectProcedure(false)}
        exitSelectProcedure={() => {
          setShowSelectProcedure(false)
          closeDetails()
        }}
        caseProcedures={caseProcedures}
        selectedProcedure={selectedProcedure}
        updateSelectedProcedure={(procedure) => setSelectedProcedure(procedure)}
      />
    )
  }

  return (
    <Layout
      header={
        <Header>
          <IconButton
            a11yTitle="back"
            onClick={closeDetails}
            icon={<ActionArrowLeftIcon />}
            size="small"
            margin={{ right: 'small' }}
          />
          <Text variant="body-copy">
            {variant === 'new' ? 'Add' : 'Edit'} an Implant
          </Text>
          <Box margin="auto" />
          {variant === 'new' && (
            <ActionButton
              variant="grey"
              onClick={closeDetails}
              icon={<CloseIcon color="orx-purple" />}
            />
          )}
        </Header>
      }
    >
      <Box flex="grow" background="champagne">
        <Box pad="medium" direction="row" align="center" justify="between">
          <Box>
            <Text
              variant="body-copy-small"
              color="steel-grey"
              margin={{ bottom: 'xsmall' }}
            >
              {caseProcedures.length > 1 ? 'Selected Procedure' : 'Procedure'}
            </Text>
            <Text>{selectedProcedure.name}</Text>
          </Box>
          {caseProcedures.length > 1 && (
            <IconButton
              a11yTitle="Select Procedure"
              onClick={() => setShowSelectProcedure(true)}
              icon={<EditIcon />}
              color="orx-green"
              size="medium"
            />
          )}
        </Box>
        <List data={implantDetailsList} fullDividers>
          {({ term, definition }) => (
            <Box>
              <Text
                variant="body-copy-small"
                color="steel-grey"
                margin={{ bottom: 'xsmall' }}
              >
                {term}
              </Text>
              <Text>{definition}</Text>
            </Box>
          )}
        </List>
        <Formik<FormikValues>
          validationSchema={validationSchema}
          initialValues={{
            implantLot,
            implantExp,
            implanted,
            wasted,
            opened,
            restock,
          }}
          onSubmit={async (values: FormikValues, { setErrors }) => {
            if (
              values.implantExp &&
              new Date(values.implantExp) <= procedureDate
            ) {
              setErrors({
                implantExp: 'Invalid Expiry Date',
              })
            } else {
              handleSubmit(
                values,
                selectedProcedure.id,
                implantAction || 'primary'
              )
            }
          }}
        >
          {({
            values,
            dirty,
            errors,
            setFieldValue,
            handleSubmit,
            submitForm,
            isSubmitting,
          }: FormikProps<FormikValues>) => (
            <Form onSubmit={handleSubmit}>
              <Box
                border={{
                  side: 'bottom',
                  size: 'xsmall',
                  color: 'white',
                }}
                pad="medium"
              >
                <Field
                  value={values.implantLot}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFieldValue('implantLot', e.target.value, false)
                  }
                  component={TextInputField}
                  name="implantLot"
                  label="LOT (Optional)"
                />
              </Box>
              <Box
                border={{
                  side: 'bottom',
                  size: 'xsmall',
                  color: 'white',
                }}
                pad="medium"
              >
                <Field
                  date={values.implantExp}
                  onChange={(date: string) => setFieldValue('implantExp', date)}
                  component={DateInputField}
                  name="implantExp"
                  label="EXP (Optional)"
                  error={errors.implantExp}
                  class="date-input"
                />
              </Box>
              <Box
                pad="medium"
                border={{
                  side: 'bottom',
                  size: 'xsmall',
                  color: 'white',
                }}
              >
                <Text
                  variant="body-copy-small"
                  color={
                    errors.implanted || errors.opened || errors.wasted
                      ? 'red'
                      : 'steel-grey'
                  }
                  margin={{ bottom: 'xsmall' }}
                >
                  Usage (Required)
                </Text>
                <Field
                  label="Implanted"
                  name="implanted"
                  value={values.implanted}
                  onChange={async (cb: StepperCB) =>
                    setFieldValue('implanted', cb(values.implanted))
                  }
                  component={StepperField}
                />
                <Field
                  label="Opened/Not Used"
                  name="opened"
                  value={values.opened}
                  onChange={(cb: StepperCB) =>
                    setFieldValue('opened', cb(values.opened))
                  }
                  component={StepperField}
                />
                <Field
                  label="Wasted"
                  name="wasted"
                  value={values.wasted}
                  onChange={(cb: StepperCB) =>
                    setFieldValue('wasted', cb(values.wasted))
                  }
                  component={StepperField}
                />
              </Box>
              <Box
                pad="medium"
                border={{
                  side: 'bottom',
                  size: 'xsmall',
                  color: 'white',
                }}
              >
                {/* rendering RadioButtonGroupField through the component/as prop on field is causing name errors to pop up - rendering as a child avoids the issue */}
                <Field name="restock">
                  {() => (
                    <RadioButtonGroupField
                      name="restock"
                      label="Restock to:"
                      value={values.restock}
                      options={restockValues}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setFieldValue('restock', e.target.value, false)
                      }
                    />
                  )}
                </Field>
              </Box>
              <Box
                direction="row"
                fill="horizontal"
                background="white"
                pad={{ horizontal: 'medium', vertical: 'small' }}
                gap="small"
              >
                <Box fill="horizontal">
                  {cloneElement(secondaryAction, {
                    size: 'small',
                    disabled:
                      variant === 'new' &&
                      (isSubmitting ||
                        !dirty ||
                        Object.entries(errors).length > 0),
                    onClick: () => {
                      setImplantAction('secondary')
                      submitForm()
                    },
                  })}
                </Box>
                <Box fill="horizontal">
                  {cloneElement(primaryAction, {
                    size: 'small',
                    disabled:
                      !selectedProcedureUpdated &&
                      (isSubmitting ||
                        !dirty ||
                        Object.entries(errors).length > 0),
                    onClick: () => {
                      setImplantAction('primary')
                      submitForm()
                    },
                  })}
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Layout>
  )
}
