import React from 'react'
import { BoxTypes } from 'grommet'
import { useNavigate } from '@reach/router'
import {
  ActionButton,
  Card,
  CardHeader,
  CardFooter,
  DescriptionList,
  DescriptionListItem,
  Heading,
} from '@orx/ui/dist'
import { ActionArrowRightIcon } from '@orx/ui/dist/icons'
import { CaseStatus, CaseStatusType } from './case-status'

export interface CaseCardProps extends Pick<BoxTypes, 'margin'> {
  caseId: string
  title: string
  facility: string
  surgeon: string
  procedures: string[]
  caseStatus: CaseStatusType
  actionButtonDisabled?: boolean
}

export const CaseCard: React.FC<CaseCardProps> = ({
  caseId,
  title,
  facility,
  surgeon,
  procedures,
  caseStatus,
  actionButtonDisabled = false,
}) => {
  const navigate = useNavigate()

  const caseDetails: DescriptionListItem[] = [
    {
      term: 'Facility',
      definition: facility,
    },
    {
      term: 'Surgeon',
      definition: surgeon,
    },
    ...procedures.map((procedure, index) => ({
      term: procedures.length > 1 ? `Procedure ${index + 1}` : 'Procedure',
      definition: procedure,
    })),
  ]

  return (
    <Card flex={false}>
      <CardHeader hasDivider={false}>
        <Heading level={2}>{title}</Heading>
      </CardHeader>
      <DescriptionList list={caseDetails} />
      <CardFooter direction="row">
        <CaseStatus caseStatus={caseStatus} />
        {/* TODO: Make an <a> */}
        <ActionButton
          icon={<ActionArrowRightIcon />}
          disabled={actionButtonDisabled}
          onClick={() => navigate(`/case/${caseId}`)}
        />
      </CardFooter>
    </Card>
  )
}
