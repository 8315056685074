import React from 'react'
import { Box } from 'grommet'
import { InfoTabFields } from './info.types'
import { CaseIdCard } from './case-id-card'
import { ProceduresCard } from './procedures-card'
import { GeneralCard } from './general-card'
import { MiscCard } from './misc-card'
import {
  CaseProcedure,
  Facility,
  Surgeon,
  Vendor,
  CaseImplant,
} from '../../case.types'

interface InfoProps extends InfoTabFields {
  caseId: string
  procedureDate: Date
  facility?: Facility
  surgeon?: Surgeon
  procedures: CaseProcedure[]
  vendors: Vendor[]
  externalFacilityCaseId?: string
  implants: CaseImplant[]
}

export const Info: React.FC<InfoProps> = ({
  caseId,
  externalFacilityCaseId,
  procedureDate,
  facility,
  surgeon,
  procedures,
  vendors,
  implants,
}) => (
  <Box gap="medium" margin={{ top: 'medium' }}>
    <CaseIdCard
      caseId={caseId}
      externalFaciltyCaseId={externalFacilityCaseId}
    />

    <ProceduresCard
      caseId={caseId}
      procedures={procedures}
      implants={implants}
    />

    <GeneralCard
      procedureDate={procedureDate}
      facility={facility}
      surgeon={surgeon}
    />

    {vendors.map(({ caseVendorId, externalVendorCaseId, vendorNote, name }) => (
      <MiscCard
        title={vendors.length === 1 ? 'Misc ' : name}
        key={caseVendorId}
        caseVendorId={caseVendorId}
        externalVendorCaseId={externalVendorCaseId}
        vendorNote={vendorNote}
      />
    ))}
  </Box>
)
