import { gql } from '@apollo/client'

export const GET_QCW_PROCEDURES_LIST = gql`
  query QCWProcedureList($where: String) {
    qcwFilter @client @export(as: "where")
    procedures(where: $where) {
      id
      name
    }
  }
`
