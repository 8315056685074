import React, { useContext, useState, useEffect } from 'react'
import { Layout } from '../../../../components'
import { Box } from 'grommet'
import {
  ActionButton,
  Button,
  Header,
  InfiniteScroll,
  Text,
  SearchInput,
  Spinner,
} from '@orx/ui/dist'
import { AddIcon, CloseIcon } from '@orx/ui/dist/icons'
import { ImplantListItem, NewImplant } from './components'
import { useImplantsQuery } from './use-implants-query'
import { Implant } from './add-implant.types'
import { implantSearch } from '../../../../store'
import { CaseProcedure } from '../../case.types'
import { CaseTabsContext } from '../case-tabs.provider'

interface Props {
  caseVendors: string[]
  caseProcedures: CaseProcedure[]
  closeAddImplant: () => void
  facilityId: string
  procedureDate: Date
}

export const AddImplantPage: React.FC<Props> = ({
  caseVendors,
  caseProcedures,
  closeAddImplant,
  facilityId,
  procedureDate,
}) => {
  const { setTabIndex, tabs } = useContext(CaseTabsContext)
  const [selectedImplantId, setSelectedImplantId] = useState<string | null>(
    null
  )
  const [filter, setFilter] = useState('')

  useEffect(() => {
    implantSearch(filter)
  }, [filter])

  const { implantsData, loading, error } = useImplantsQuery(caseVendors)

  if (selectedImplantId) {
    return (
      <NewImplant
        closeDetails={() => setSelectedImplantId(null)}
        closeAddImplant={closeAddImplant}
        implantId={selectedImplantId}
        caseProcedures={caseProcedures}
        facilityId={facilityId}
        procedureDate={procedureDate}
      />
    )
  }

  if (error) {
    return (
      <Layout
        header={
          <Header>
            <Text variant="body-copy">Add an Implant</Text>
            <Box margin="auto" />
            <ActionButton
              variant="grey"
              onClick={closeAddImplant}
              icon={<CloseIcon color="orx-purple" />}
            />
          </Header>
        }
      >
        <Box as="main" flex="grow" background="champagne">
          {error && <Text margin="auto">{error.message}</Text>}
        </Box>
      </Layout>
    )
  }

  return (
    <Layout
      header={
        <Header>
          <Text variant="body-copy">Add an Implant</Text>
          <Box margin="auto" />
          <ActionButton
            variant="grey"
            onClick={closeAddImplant}
            icon={<CloseIcon color="orx-purple" />}
          />
        </Header>
      }
      subHeader={
        <Box round="xsmall" background="white" fill="horizontal">
          <SearchInput
            placeholder="Search by Name or REF"
            value={filter}
            setValue={setFilter}
          />
        </Box>
      }
    >
      <Box as="main" flex="grow" background="champagne" overflow="auto">
        {loading && (
          <Box flex="grow" justify="center" align="center">
            <Spinner />
            <Text margin={{ top: 'small' }}>Loading....</Text>
          </Box>
        )}
        {!loading && implantsData?.length === 0 ? (
          <Box flex="grow" align="center" justify="center" gap="small">
            <Text>Couldn&apos;t find find your implant?</Text>
            <Button
              label="Add an Extra Item"
              variant="primary"
              icon={<AddIcon size="small" />}
              onClick={() => {
                setTabIndex(tabs['extra-items'])
                closeAddImplant()
              }}
            />
          </Box>
        ) : (
          implantSearch() &&
          implantsData && (
            <InfiniteScroll items={implantsData} step={25}>
              {({ id, name, description, refId, vendor, sizes }: Implant) => (
                <Box
                  key={id}
                  pad="medium"
                  border={{ size: 'small', color: 'white', side: 'top' }}
                  onClick={() => setSelectedImplantId(id)}
                >
                  <ImplantListItem
                    implantId={id}
                    implantName={name}
                    implantDescription={description}
                    refId={refId}
                    vendorName={vendor.name}
                    sizes={sizes}
                  />
                </Box>
              )}
            </InfiniteScroll>
          )
        )}
      </Box>
    </Layout>
  )
}
