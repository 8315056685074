import { useContext } from 'react'
import { useMutation, gql, FetchResult } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { INFO_TAB_FIELDS_FRAGMENT, InfoTabFields } from './info/info.types'
import { CaseConcludeContext } from './case-conclude.provider'

const UPDATE_CASE = gql`
  mutation UpdateCase($caseId: ID!, $values: CaseUpdate!) {
    updateCase(id: $caseId, updateCaseData: $values) {
      id
      ...InfoTabFields
    }
  }
  ${INFO_TAB_FIELDS_FRAGMENT}
`

type CaseUpdate = InfoTabFields

interface MutationData {
  updateCase: {
    id: string
    externalFacilityCaseId?: string
    externalVendorCaseId?: string
    vendorNote?: string
  }
}

interface MutationVariables {
  caseId: string
  values: CaseUpdate
}

type UpdateCase = {
  loading: boolean
  updateCase: (
    values: CaseUpdate
  ) => Promise<
    FetchResult<
      MutationData,
      Record<string, MutationData>,
      Record<string, MutationData>
    >
  >
}

export function useUpdateCase(caseId: string): UpdateCase {
  const { removeErrors, startActiveUpdate } = useContext(CaseConcludeContext)
  const toast = useToast()
  const [update, { loading }] = useMutation<MutationData, MutationVariables>(
    UPDATE_CASE,
    {
      onCompleted() {
        toast('Case ID updated', { variant: 'success' })
        removeErrors('externalFacilityCaseId')
      },
      onError(error) {
        console.error(error)
        toast('There was an error updating this Case ID.', {
          variant: 'danger',
        })
      },
    }
  )

  async function updateCase(values: CaseUpdate) {
    const finish = startActiveUpdate('UPDATE_CASE')
    return update({
      variables: { caseId, values },
    }).finally(finish)
  }

  return {
    updateCase,
    loading,
  }
}
