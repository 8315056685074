import { useQuery, gql, ApolloError } from '@apollo/client'
import { Implant, IMPLANTS_FRAGMENT } from './add-implant.types'

export const IMPLANTS_QUERY = gql`
  query Implants($vendors: [UUID], $searchString: String) {
    implantSearch @client @export(as: "searchString")
    implants(
      implantSearch: { vendors: $vendors, searchString: $searchString }
    ) {
      ...Implants
    }
  }
  ${IMPLANTS_FRAGMENT}
`

interface QueryData {
  implants: Implant[]
}

interface QueryVariables {
  vendors: string[]
}

type ImplantsData = Implant[] | null

export function useImplantsQuery(
  caseVendors: string[]
): {
  implantsData: ImplantsData
  loading: boolean
  error?: ApolloError
} {
  const { data, loading, error } = useQuery<QueryData, QueryVariables>(
    IMPLANTS_QUERY,
    {
      variables: {
        vendors: caseVendors,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  // alpha sort implantsData
  const implantsData = !data?.implants
    ? null
    : data?.implants
        .slice()
        .sort((a, b) => a.vendor.name.localeCompare(b.vendor.name))

  return { implantsData, loading, error }
}
