import React, { useState, ComponentType } from 'react'
import { Box } from 'grommet'
import { SubHeader } from '@orx/ui/dist'
import { DefaultHeader } from './default-header'
import { LayoutSideBar } from './side-bar'
import styled from 'styled-components'

const Wrapper = styled.div`
  min-height: 100vh;
  flex-grow: 1;
  display: grid;
  grid-template-rows: auto 1fr auto;
`

interface LayoutProps {
  title?: string
  HeaderAction?: ComponentType
  header?: JSX.Element
  subHeader?: JSX.Element
  bottomBar?: JSX.Element
}

export const Layout: React.FC<LayoutProps> = ({
  children,
  title,
  HeaderAction,
  header,
  subHeader,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)

  return (
    <Wrapper background="champagne">
      <Box flex={false}>
        {header || (
          <DefaultHeader
            title={title}
            setIsMenuOpen={setIsMenuOpen}
            Action={HeaderAction}
          />
        )}
        {subHeader && <SubHeader>{subHeader}</SubHeader>}
      </Box>

      <Box flex="grow">{children}</Box>

      {isMenuOpen && <LayoutSideBar closeMenu={() => setIsMenuOpen(false)} />}
    </Wrapper>
  )
}
