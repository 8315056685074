import { useMutation, gql } from '@apollo/client'
import {
  CASE_IMPLANTS_FRAGMENT,
  CaseProcedureImplant,
} from '../add-implant.types'

const CREATE_PROCEDURE_IMPLANT = gql`
  mutation CreateCaseProcedureImplant($values: CaseProcedureImplantCreate) {
    createCaseProcedureImplant(createCaseProcedureImplantData: $values) {
      id
      caseProcedure {
        id
        ...CaseImplants
      }
    }
  }
  ${CASE_IMPLANTS_FRAGMENT}
`

interface MutationData {
  caseProcedureImplant: {
    readonly id: string
    caseProcedure: {
      readonly id: string
      implants: CaseProcedureImplant[]
    }
  }
}

interface MutationVariables {
  values: {
    CaseProcedureId: string
    ImplantId: string
    lotNumber: string
    expirationDate: string | null
    restockTo: string
    implanted: number
    opened: number
    wasted: number
    unitPrice: number
  }
}

export function useCreateProcedureImplant(
  onSuccess: () => void,
  onCreateError: () => void
): (values: MutationVariables) => Promise<void> {
  const [create] = useMutation<MutationData, MutationVariables>(
    CREATE_PROCEDURE_IMPLANT,
    {
      onCompleted() {
        onSuccess()
      },
      onError(error) {
        onCreateError()
        console.error(error)
      },
    }
  )

  return async (values: MutationVariables) => {
    create({
      variables: values,
    })
  }
}
