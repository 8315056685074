import React from 'react'
import { Box, Grid } from 'grommet'
import { Button, Text } from '@orx/ui/dist'
import { WizardListItemProps } from './wizard-list-item.types'

export const WizardListItem: React.FC<WizardListItemProps> = ({
  isSelected,
  name,
  address,
  buttonText,
  buttonSelectedText,
  onSelect,
}) => {
  // Flexbox and "truncate" feature do funny things with calculating width. Using Grid to avoid it.
  return (
    <Grid
      columns={['auto', '4.7rem']}
      rows={['auto']}
      areas={[['content', 'actions']]}
      gap="small"
    >
      <Box gridArea="content" justify="center">
        <Text variant="body-copy" truncate={2}>
          {name}
        </Text>
        {address && (
          <Text variant="body-copy-small" margin={{ top: 'xsmall' }}>
            {address}
          </Text>
        )}
      </Box>
      <Box gridArea="actions" justify="center">
        <Button
          onClick={onSelect}
          variant={isSelected ? 'secondary' : 'primary'}
          size="small"
          label={isSelected ? buttonSelectedText : buttonText}
        />
      </Box>
    </Grid>
  )
}
