import React, { useState, ChangeEvent } from 'react'
import styled from 'styled-components'
import { Box } from 'grommet'
import {
  Button,
  ConfirmationModal,
  Card,
  CardHeader,
  IconButton,
  Text,
  CurrencyField,
  TextInputField,
  TextAreaField,
  SelectField,
} from '@orx/ui/dist'
import { TrashIcon } from '@orx/ui/dist/icons'
import { Formik, Form as FormikForm, Field } from 'formik'
import * as Yup from 'yup'
import { FormValues } from './extra-items.types'
import { Vendor } from '../../case.types'

const Form = styled(FormikForm)`
  margin-bottom: 0;
`

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  caseVendorId: Yup.string().required('Required').uuid(),
  price: Yup.string()
    .required('Required')
    .matches(/^\$\d+\.\d{1,2}$/, 'Must have two decimal places'),
  description: Yup.string(),
})

interface FormInputValues {
  name: string
  caseVendorId: string
  price: string
  description: string
}

function parseValues({
  caseVendorId,
  price,
  ...values
}: FormInputValues): FormValues {
  if (!caseVendorId || !price) throw new Error('Invalid form values.')
  return {
    ...values,
    caseVendorId: caseVendorId,
    price: parseFloat(price.trim().slice(1)),
  }
}

interface Props {
  itemId?: string
  index: number
  name?: string
  caseVendorId?: string
  price?: number
  description?: string
  vendors: Vendor[]
  submitButtonText: string
  onSubmit: (values: FormValues) => Promise<void>
  onCancel?: () => void
  onDelete: (itemId?: string) => void
}

export const ExtraItemForm: React.FC<Props> = ({
  itemId,
  index,
  name = '',
  caseVendorId = '',
  price,
  description = '',
  vendors,
  submitButtonText,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const [
    isDeleteConfirmationOpen,
    setIsDeleteConfirmationOpen,
  ] = useState<boolean>(false)

  return (
    <Box margin={{ vertical: 'medium' }}>
      <Card>
        <CardHeader hasDivider>
          <Box direction="row" justify="between">
            <Text variant="large-heading">Item {index + 1}</Text>
            <IconButton
              a11yTitle="Delete"
              size="small"
              color="orx-green"
              icon={<TrashIcon />}
              onClick={() => setIsDeleteConfirmationOpen(true)}
            />
            <ConfirmationModal
              open={isDeleteConfirmationOpen}
              title="Delete Extra Item"
              body="Are you sure you want to delete this extra item?"
              confirmLabel="Delete"
              confirm={() => onDelete(itemId)}
              closeModal={() => setIsDeleteConfirmationOpen(false)}
            />
          </Box>
        </CardHeader>
        <Formik<FormInputValues>
          validationSchema={validationSchema}
          initialValues={{
            name,
            caseVendorId,
            price: price != null ? `$${price.toFixed(2)}` : '',
            description,
          }}
          onSubmit={(values) => onSubmit(parseValues(values))}
        >
          {({
            values,
            isSubmitting,
            touched,
            errors,
            setFieldValue,
            setFieldTouched,
            dirty,
          }) => (
            <Form>
              <Field
                name="name"
                component={TextInputField}
                label="Name (Required)"
                disabled={isSubmitting}
                value={values.name}
                error={touched.name && errors.name}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('name', e.target.value)
                }
                onBlur={() => setFieldTouched('name', true)}
              />

              <Field
                name="caseVendorId"
                label="Vendor (Required)"
                component={SelectField}
                disabled={isSubmitting}
                value={values.caseVendorId}
                error={touched.caseVendorId && errors.caseVendorId}
                options={vendors.map((vendor) => ({
                  value: vendor.caseVendorId,
                  label: vendor.name,
                }))}
                valueKey={{ key: 'value', reduce: true }}
                labelKey="label"
                onChange={({ value }: { value: string }) =>
                  setFieldValue('caseVendorId', value)
                }
              />

              <Field
                name="price"
                component={CurrencyField}
                label="Price (Required)"
                disabled={isSubmitting}
                value={values.price}
                error={touched.price && errors.price}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (!touched.price) {
                    setFieldTouched('price', true)
                  }
                  setFieldValue('price', e.target.value)
                }}
              />

              <Field
                full
                name="description"
                component={TextAreaField}
                label="Description (Optional)"
                disabled={isSubmitting}
                value={values.description}
                error={touched.description && errors.description}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  setFieldValue('description', e.target.value)
                }
              />

              <Box
                direction="row"
                alignContent="center"
                justify="end"
                gap="small"
              >
                {onCancel && (
                  <Button
                    variant="secondary"
                    size="small"
                    label="Cancel"
                    onClick={onCancel}
                  />
                )}
                <Button
                  type="submit"
                  variant="primary"
                  size="small"
                  label={submitButtonText}
                  disabled={!validationSchema.isValidSync(values) || !dirty}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </Box>
  )
}
