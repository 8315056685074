import React, { MouseEvent } from 'react'
import { Box } from 'grommet'
import { Button, DescriptionList, List, Text } from '@orx/ui/dist'
import { SummaryBarProps } from './summary-bar.types'
import { SummaryItem } from '../../quick-case-wizard.page.types'
import { AccordionActionBar, ACTION_BAR_HEIGHT } from '../../../../components'
import styled from 'styled-components'

const ScrollableBox = styled(Box)`
  max-height: calc(100vh - ${ACTION_BAR_HEIGHT});
  z-index: 25;
`

export const SummaryBar: React.FC<SummaryBarProps> = ({
  summaryList,
  canContinue,
  onContinue,
}) => {
  return (
    <AccordionActionBar
      title="Case Summary"
      actions={
        <Button
          variant="primary"
          disabled={!canContinue}
          margin={{ left: 'auto' }}
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            onContinue(event)
          }}
          label="Continue"
        />
      }
    >
      <ScrollableBox overflow={{ vertical: 'scroll' }} background="white">
        <Text variant="xlarge-heading" margin="medium">
          Case Summary
        </Text>
        <Box>
          <List data={summaryList} dividerColor="ice-grey" fullDividers={true}>
            {(items: SummaryItem[]) => (
              <DescriptionList
                list={items.map((i) => ({
                  term: i.title,
                  definition: i.name,
                }))}
              />
            )}
          </List>
        </Box>
      </ScrollableBox>
    </AccordionActionBar>
  )
}
