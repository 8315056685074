import { gql } from '@apollo/client'
import { CaseProcedureImplant } from './components/add-implant/add-implant.types'
import { CaseStatusType } from '../../components/case-status'
import { Sizes } from './components/add-implant/add-implant.types'

export interface Address {
  readonly id: string
  street: string
  unit: string
  city: string
  state: string
  zipCode: string
}

export interface Facility {
  readonly id: string
  name: string
  phoneNumber: string
  address: Address
}

export interface Surgeon {
  readonly id: string
  fullName: string
  specialty: string
}

export interface CaseProcedure {
  readonly id: string
  procedureId: string
  name: string
  description?: string
}

export interface User {
  readonly id: string
  fullName: string
  email: string
  address: Address
}

export interface CaseImplant {
  readonly id: string
  implantId: string
  implantName: string
  implantDescription: string
  implantSizes: Sizes[] | null
  externalImplantId: string
  refId: string
  lotNumber: string
  expirationDate: string | null
  restockTo: string
  implanted: number
  opened: number
  wasted: number
  unitPrice: number
  extendedPrice: {
    implanted: number
    opened: number
    wasted: number
  }
  vendor: string
  procedure: {
    caseProcedureId: string
    procedureId: string
  }
}

export interface Vendor {
  readonly id: string
  readonly caseVendorId: string
  name: string
  address: Address
  phoneNumber: string
  externalVendorCaseId?: string
  vendorNote?: string
}

export type VendorOption = Pick<Vendor, 'caseVendorId' | 'name'>

export interface CaseExtraItemData {
  readonly id: string
  name: string
  price: number
  description?: string
  readonly createdAt: string
}

export interface ExtraItem {
  readonly id: string
  readonly caseVendorId: string
  name: string
  price: number
  description?: string
  readonly createdAt: Date
}

export const CASE_EXTRA_ITEM_FRAGMENT = gql`
  fragment CaseExtraItem on ExtraItem {
    id
    name
    price
    description
  }
`

interface CaseBase {
  readonly id: string
  status: CaseStatusType
  externalFacilityCaseId?: string
  facility: Facility
  surgeon: Surgeon
  user: User
}

export interface Case extends CaseBase {
  procedureDate: Date
  procedures: CaseProcedure[]
  caseVendors: Vendor[]
  implants: CaseImplant[]
  extraItems: ExtraItem[]
  isConcluded: boolean
}

export interface CaseQueryData extends CaseBase {
  procedureDate: string
  caseProcedures: {
    readonly id: string
    description?: string
    procedure: {
      readonly id: string
      name: string
    }
    caseProcedureImplants: CaseProcedureImplant[]
  }[]
  isConcluded: boolean
  caseVendors?: {
    readonly id: string
    vendor: Vendor
    externalVendorCaseId: string
    vendorNote: string
    extraItems: {
      readonly id: string
      name: string
      price: number
      description?: string
      readonly createdAt: string
    }[]
    vendorNotes?: string
  }[]
}
