import { useMutation, gql } from '@apollo/client'
import { useToast } from '@orx/ui/dist'

const DELETE_PROCEDURE_IMPLANT = gql`
  mutation deleteCaseProcedureImplant($id: ID!) {
    deleteCaseProcedureImplant(id: $id)
  }
`

interface MutationData {
  deleteCaseProcedureImplant: boolean
}

interface MutationVariables {
  id: string
}

export function useDeleteProcedureImplant(
  onSuccess: () => void
): (caseProcedureImplantId: string) => Promise<void> {
  const toast = useToast()
  const [deleteImplant] = useMutation<MutationData, MutationVariables>(
    DELETE_PROCEDURE_IMPLANT,
    {
      onCompleted() {
        toast('Implant Deleted', { variant: 'success' })
        onSuccess()
      },
      onError(error) {
        toast('Could not delete implant', { variant: 'danger' })
        console.error(error)
      },
    }
  )

  return async (caseProcedureImplantId: string) => {
    deleteImplant({
      variables: {
        id: caseProcedureImplantId,
      },
      update(cache) {
        // AC3 also suggests using cache.modify to remove/delete from the cache but was unable to correctly targer/modeify the cache with it. I think this may be due to the fragment/nesting structure of the incoming data
        cache.evict({ id: `CaseProcedureImplant:${caseProcedureImplantId}` })
        cache.gc()
      },
    })
  }
}
