import React, { useState } from 'react'
import { Box, DropButton } from 'grommet'
import { ActionButton, List, Text } from '@orx/ui/dist'
import { ActionArrowRightIcon, EditIcon } from '@orx/ui/dist/icons'
import { ConfirmationStepProps } from './confirmation-step.types'
import { SummaryItem } from '../../quick-case-wizard.page.types'
import {
  setSurgeryTime,
  SURGERY_DATE_TIME,
} from '../../quick-case-wizard.page.reducer'
import { SurgeryTimePicker } from '../surgery-time-picker'

export const ConfirmationStep: React.FC<ConfirmationStepProps> = ({
  confirmationList,
  onAction,
  surgeryTime,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <List data={confirmationList}>
      {(list) => {
        const isEditDateTimeAction = list[0].title === SURGERY_DATE_TIME
        return (
          <Box align="center" gap="xsmall" justify="between" direction="row">
            <Box direction="column" fill="horizontal">
              {(list as SummaryItem[]).map((item, index) => (
                <Box justify="center" key={index}>
                  <Text
                    as="h4"
                    variant="body-copy-small"
                    color="steel-grey"
                    margin={{ top: index ? 'small' : '0', bottom: 'xsmall' }}
                  >
                    {item.title}
                  </Text>
                  <Text variant="body-copy">{item.name}</Text>
                  {item.address && (
                    <Text variant="body-copy-small" margin={{ top: 'xsmall' }}>
                      {item.address}
                    </Text>
                  )}
                </Box>
              ))}
            </Box>
            <Box align="center">
              {isEditDateTimeAction && (
                <DropButton
                  open={open}
                  onClose={() => setOpen(false)}
                  onOpen={() => setOpen(true)}
                  dropContent={
                    <SurgeryTimePicker
                      time={surgeryTime}
                      onCancel={() => setOpen(false)}
                      onNewTime={(time) => {
                        onAction(setSurgeryTime(time))
                        setOpen(false)
                      }}
                    />
                  }
                >
                  <EditIcon color="orx-green" />
                </DropButton>
              )}
              {!isEditDateTimeAction && (
                <ActionButton
                  a11yTitle="Edit"
                  onClick={() => onAction(list[0].action)}
                  size="medium"
                  icon={<ActionArrowRightIcon />}
                />
              )}
            </Box>
          </Box>
        )
      }}
    </List>
  )
}
