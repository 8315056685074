import { makeVar } from '@apollo/client'
import { DateTime } from 'luxon'

interface User {
  firstName: string
  lastName: string
  fullName: string
  email: string
  role: string
}

export const bearerToken = makeVar<string | null>(null)
export const caseFilterDate = makeVar<string>(
  DateTime.now().toISO({ includeOffset: true })
)
export const qcwFilter = makeVar<string | null>(null)
export const implantSearch = makeVar<string | null>(null)
export const userVar = makeVar<User | null>(null)
