import React from 'react'
import { Button, Box } from 'grommet'
import { Text } from '@orx/ui/dist'

interface Props {
  icon?: JSX.Element
  label: string
  onClick: () => void
}

// TODO: should further investigate how to utilize anchor/link tags stylized as butttons. For now utilize 'as' prop.
export const SidebarButton: React.FC<Props> = ({ icon, label, onClick }) => {
  return (
    <Box border={{ size: 'small', side: 'bottom', color: 'ice-grey' }}>
      <Button plain as="a" onClick={onClick}>
        <Box
          direction="row"
          align="center"
          justify="start"
          gap="small"
          pad={{ horizontal: 'medium', vertical: 'large' }}
        >
          {icon}
          <Text>{label}</Text>
        </Box>
      </Button>
    </Box>
  )
}
