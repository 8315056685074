import React, { useContext } from 'react'
import { useMutation, gql, StoreObject } from '@apollo/client'
import { useToast } from '@orx/ui/dist'
import { ExtraItemForm } from './extra-item-form'
import { EXTRA_ITEM_FRAGMENT } from './extra-items.types'
import { CaseConcludeContext } from '../case-conclude.provider'
import { ExtraItem, Vendor } from '../../case.types'

// Update
const UPDATE_EXTRA_ITEM = gql`
  mutation UpdateExtraItem($itemId: ID!, $values: ExtraItemUpdate!) {
    updateExtraItem(id: $itemId, updateExtraItemData: $values) {
      ...CaseUpdateExtraItem
    }
  }
  ${EXTRA_ITEM_FRAGMENT}
`

interface UpdateExtraItemData {
  updateExtraItem: ExtraItem
}

interface UpdateExtraItemVariables {
  itemId: string
  values: {
    name: string
    CaseVendorId: string
    price: number
    description?: string
  }
}

// Delete
const DELETE_EXTRA_ITEM = gql`
  mutation DeleteExtraItem($itemId: ID!) {
    deleteExtraItem(id: $itemId)
  }
`

interface DeleteExtraItemVariables {
  itemId: string
}

interface UpdateExtraItemProps {
  itemId: string
  index: number
  name: string
  caseVendorId: string
  price: number
  description?: string
  vendors: Vendor[]
}

export const UpdateExtraItem: React.FC<UpdateExtraItemProps> = ({
  itemId,
  caseVendorId,
  ...props
}) => {
  const toast = useToast()
  const { startActiveUpdate } = useContext(CaseConcludeContext)

  const [update] = useMutation<UpdateExtraItemData, UpdateExtraItemVariables>(
    UPDATE_EXTRA_ITEM,
    {
      onCompleted() {
        toast('Item Updated', { variant: 'success' })
      },
      onError(error) {
        console.error(error)
        toast('Could not update item', { variant: 'danger' })
      },
    }
  )

  const [deleteItem] = useMutation<null, DeleteExtraItemVariables>(
    DELETE_EXTRA_ITEM,
    {
      update(cache) {
        cache.modify({
          id: `CaseVendor:${caseVendorId}`,
          fields: {
            extraItems(prev: StoreObject[], { readField }) {
              return prev.filter((a) => readField('id', a) !== itemId)
            },
          },
        })
      },
      onCompleted() {
        toast('Item Deleted', { variant: 'success' })
      },
      onError(error) {
        console.error(error)
        toast('Could not delete item', { variant: 'danger' })
      },
    }
  )

  async function handleSubmit({
    caseVendorId,
    ...values
  }: ExtraItem): Promise<void> {
    const finish = startActiveUpdate('UPDATE_EXTRA_ITEM')
    await update({
      variables: {
        itemId,
        values: {
          ...values,
          CaseVendorId: caseVendorId,
        },
      },
    }).finally(finish)
  }

  async function handleDelete(): Promise<void> {
    const finish = startActiveUpdate('DELETE_EXTRA_ITEM')
    await deleteItem({
      variables: { itemId },
    }).finally(finish)
  }

  return (
    <ExtraItemForm
      {...props}
      caseVendorId={caseVendorId}
      submitButtonText="Update"
      onSubmit={handleSubmit}
      onDelete={handleDelete}
    />
  )
}
